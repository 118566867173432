import { ChimneysSpecification } from "@airmont/firefly/my-chimney/ts/building";
import {
  AddressDto,
  ChimneyHatStringsEnum,
  ChimneySensorPlacementStringEnum,
  FlueMetricsFactory,
  FlueTypeStringsEnum,
  YesNoUnknownStringEnum,
} from "@airmont/firefly/shared/ts/domain";

export const createOpalveien15 = (
  address: AddressDto
): ChimneysSpecification => {
  return {
    chimneys: [
      {
        fields: {
          hat: ChimneyHatStringsEnum.Yes,
        },
        flues: [`${address.id}.f-1`, `${address.id}.f-2`].map(
          (flueId, flueIndex) => {
            return {
              type: "Flue",
              id: flueId,
              securityToken: "f-1",
              audit: {
                created: "2022-11-01T12:00:00",
                createdBy: "bos@airmont.no",
              },
              mcFields: {
                name: ["Stue", "Kjellerleilighet"][flueIndex],
              },
              fields: {
                type: FlueTypeStringsEnum.Steel,
                height: 8,
                sensorPlacement: ChimneySensorPlacementStringEnum.Roof,
              },
              fireplaces: [`${flueId}.fp-1`].map((firePlaceId) => {
                return {
                  type: "Fireplace",
                  id: firePlaceId,
                  securityToken: "fp-1",
                  flueId: flueId,
                  mcFields: {
                    name: ["Contura", "Thermatech"][flueIndex],
                  },
                  fields: {
                    catalytic: YesNoUnknownStringEnum.Yes,
                  },
                };
              }),
              metrics: [
                FlueMetricsFactory.createSinceSweepDto({
                  flueId: flueId,
                  metrics: {
                    burnCount: 151,
                    burnHourCount: 1501,
                    sootIndex: 231,
                    temperatureMax: 335,
                    chimneyFireCount: 0,
                  },
                }),
              ],
            };
          }
        ),
      },
    ],
  };
};
