import { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { PanelResizeHandle } from "react-resizable-panels";
import styled from "styled-components";
import { Divider, Typography, useTheme } from "@mui/material";
import { merge } from "lodash";
import { Variant } from "../Variant";

const hPath =
  "M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z";
const vPath =
  "M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z";

export const StyledResizeHandle = styled(PanelResizeHandle)<{
  $width: number | string;
  $backgroundColor: string;
}>`
  flex: 0 0 ${(props) => props.$width};
  position: relative;
  outline: none;

  background-color: ${({ $backgroundColor }) => $backgroundColor};

  > .MuiDivider-root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    transition: background-color 0.2s linear;
  }

  svg {
    width: 1em;
    height: 1em;
    position: absolute;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
  }
`;

export interface ResizeHandleProps {
  type: "regular" | "mobile";
  variant: Variant;
  thin?: boolean;
  collapsed?: boolean;
  label?: string;
  orientation: "horizontal" | "vertical";
  disableDivider?: boolean;
  hideHandle?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onOpen?: () => void;
}

export const ResizeHandle: FC<ResizeHandleProps> = (props) => {
  const { orientation, variant } = props;
  const disabled = props.disabled ?? false;
  const disableDivider =
    disabled ||
    (props.disableDivider ?? (variant === "flat" || variant === "elevation"));
  const collapsed = props.collapsed ?? false;
  const hideLabel = props.hideLabel != null ? props.hideLabel : !collapsed;
  const hideHandle =
    disabled || !hideLabel || (props.hideHandle ?? variant === "flat");

  const thin = disabled || (props.thin ?? false);
  const theme = useTheme();
  const [hovering, setHovering] = useState(false);
  const [clicked, setClicked] = useState(false);

  const width = thin ? "2px" : "24px";
  const backgroundColorDefault = theme.palette.background.default;
  const backgroundColorWhenHovering = theme.palette.action.hover;
  const backgroundColorWhenClicked = theme.palette.action.hover;
  const backgroundColor = clicked
    ? backgroundColorWhenClicked
    : hovering
    ? backgroundColorWhenHovering
    : backgroundColorDefault;

  const rootStyle = useMemo(() => {
    return merge({ margin: 0 }, props.style, {
      position: "relative",

      ...(props.type === "mobile" && {
        borderTop: "1px solid " + theme.palette.divider,
        borderLeft: "1px solid " + theme.palette.divider,
        borderRight: "1px solid " + theme.palette.divider,
        borderTopLeftRadius: "9px",
        borderTopRightRadius: "9px",
      }),
    } as CSSProperties);
  }, [props.style, props.type, theme.palette.divider]);

  useEffect(() => {
    setHovering(false);
    setClicked(false);
  }, [collapsed]);

  const handleClick = () => {
    if (collapsed) {
      props.onOpen?.();
    }
  };

  const handleMouseEnter = () => {
    if (!disabled) {
      setHovering(true);
    }
  };
  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleMouseDown = () => {
    if (!disabled) {
      setClicked(true);
    }
  };

  const handleMouseUp = () => {
    setClicked(false);
  };
  return (
    <StyledResizeHandle
      $backgroundColor={backgroundColor}
      $width={width}
      style={rootStyle}
      disabled={disabled}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.label != null && !hideLabel && (
        <Typography
          component={"div"}
          variant={"button"}
          color={"secondary"}
          align={orientation === "horizontal" ? "center" : undefined}
          sx={{
            ...(orientation === "horizontal" && {
              paddingLeft: 1,
              paddingRight: 1,
            }),
            ...(orientation === "vertical" && {
              position: "absolute",
              top: "50%",
              bottom: "50%",
              transform: "translateX(-50%) translateY(-50%) rotate(-90deg)",
              minWidth: "unset",
              whiteSpace: "nowrap",
            }),
          }}
        >
          {props.label}
        </Typography>
      )}
      {!disableDivider && (
        <Divider orientation={orientation}>
          {!hideHandle && (
            <svg viewBox="0 0 24 24">
              <path
                fill={theme.palette.divider}
                d={orientation === "horizontal" ? vPath : hPath}
              />
            </svg>
          )}
        </Divider>
      )}
      {disableDivider && !hideHandle && (
        <svg viewBox="0 0 24 24">
          <path
            fill={theme.palette.divider}
            d={orientation === "horizontal" ? vPath : hPath}
          />
        </svg>
      )}
    </StyledResizeHandle>
  );
};
