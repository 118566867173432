import React, { FC, useMemo } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Stack, Toolbar } from "@mui/material";
import { Deck } from "@airmont/shared/ts/ui/deck";
import { useMyChimneyContext } from "../MyChimneyContext";
import { ChimneyPanel } from "./ChimneyPanel";
import {
  BuildingSelect,
  FlueMetricTimeSelect,
  FlueSelect,
  useFlueMetricTimeItems,
} from "@airmont/firefly/my-chimney/ts/building";
import {
  AddressId,
  FlueId,
  FlueMetricNaturalKeyImpl,
} from "@airmont/firefly/shared/ts/domain";
import { useResizeDetector } from "react-resize-detector";
import { useTranslation } from "react-i18next";
import {
  SizeClass,
  useWindowHeight,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export const KeyFiguresPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { width, height, ref } = useResizeDetector();
  const widthAsSizeClass = useWindowWidth();
  const heightAsSizeClass = useWindowHeight();
  const layout = useMemo(() => {
    if (height == null) {
      return SizeClass.Medium;
    }
    if (height < 700) {
      return SizeClass.Compact;
    }
    return heightAsSizeClass;
  }, [heightAsSizeClass, height]);
  const now = useDateTimeNow();
  const {
    buildings,
    selectedBuilding,
    setSelectedBuilding,
    selectedChimney,
    selectedFlue,
    setSelectedFlue,
    gotoNextChimney,
    gotoPreviousChimney,
  } = useMyChimneyContext();
  const indexOfSelectedChimney =
    selectedBuilding.getIndexOfChimney(selectedChimney);
  const {
    flueMetricTimeItems,
    selectedFlueMetricTimeItem,
    handleFlueMetricTimeItemSelect,
  } = useFlueMetricTimeItems(
    "KeyFiguresPage",
    selectedFlue,
    FlueMetricNaturalKeyImpl.month(selectedFlue.id, now.startOf("month"))
  );
  const handleBuildingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };
  const handleSelectedFlue = (flueId: FlueId) => {
    setSelectedFlue(flueId);
  };

  /*const handleGotoNextChimney = () => {
    gotoNextChimney();
  };*/

  /*const handleGotoPreviousChimney = () => {
    gotoPreviousChimney();
  };*/

  return (
    <AppPage
      $key={"KeyFiguresPage"}
      name={t("Overview")}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Stack
            direction={"column"}
            gap={1}
            useFlexGap
            sx={{ flexGrow: 1 }}
            ref={ref}
          >
            <Toolbar
              disableGutters
              variant={"dense"}
              sx={{
                justifyContent:
                  widthAsSizeClass === SizeClass.ExtraLarge
                    ? "start"
                    : "center",
              }}
            >
              <BuildingSelect
                buildings={buildings}
                selected={selectedBuilding.id}
                onChange={handleBuildingChange}
              />
              {selectedBuilding.getNumberOfFlues() > 1 && (
                <FlueSelect
                  building={selectedBuilding}
                  selectedFlue={selectedFlue}
                  onSelectFlue={handleSelectedFlue}
                  sx={{ ml: 1 }}
                />
              )}
            </Toolbar>
            <FlueMetricTimeSelect
              items={flueMetricTimeItems}
              selected={selectedFlueMetricTimeItem}
              onSelect={handleFlueMetricTimeItemSelect}
              color={"inherit"}
            />
            <Deck
              className={"ChimneyDeck"}
              sx={{
                width: "100%",
                flexGrow: 1,
                minWidth: 0,
                minHeight: 0,
                maxHeight: "700px",
              }}
              selected={indexOfSelectedChimney}
              renderNavigationButtons={false}
            >
              {selectedBuilding.chimneys.map((chimney) => {
                return (
                  <ChimneyPanel
                    key={chimney.id}
                    customerEnvironment={selectedBuilding.environment.id}
                    chimney={chimney}
                    selectedFlueMetric={selectedFlueMetricTimeItem}
                    layout={layout}
                  />
                );
              })}
            </Deck>
          </Stack>
        </Box>
      }
    />
  );
};
