import React, { FC, ReactNode } from "react";
import { IconButton } from "@mui/material";
import { Button } from "../button/Button";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { Button_variant } from "../types/muiTypes";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";

export interface CycleButtonProps {
  value: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  icon?: ReactNode;
  label?: ReactNode;
  tooltip?: ReactNode;
  type: "IconButton" | "Button";
  variant?: Button_variant;
  onClick?: (value: string) => void;
}

export const CycleButton: FC<CycleButtonProps> = (props) => {
  const handleClick = () => {
    props.onClick?.(props.value);
  };
  if (props.type === "IconButton") {
    if (props.icon == null) {
      throw new IllegalArgumentError("icons must be given type is IconButton");
    }
    return <IconButton onClick={handleClick}>{props.icon}</IconButton>;
  } else if (props.type === "Button") {
    return (
      <TooltipOrNot title={props.tooltip}>
        <Button
          variant={props.variant}
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          onClick={handleClick}
        >
          {props.label ?? null}
        </Button>
      </TooltipOrNot>
    );
  } else {
    throw new IllegalArgumentError("Illegal type: " + props.type);
  }
};
