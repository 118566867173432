import React, { FC, MouseEvent, ReactElement, useMemo } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMyChimneyContext } from "../MyChimneyContext";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { BurnQualityContent } from "./BurnQualityContent";
import {
  BuildingSelect,
  FlueMetricTimeSelect,
  FlueSelect,
  useFlueMetricTimeItems,
} from "@airmont/firefly/my-chimney/ts/building";
import {
  AddressId,
  FlueId,
  FlueMetricNaturalKeyImpl,
} from "@airmont/firefly/shared/ts/domain";
import { useResizeDetector } from "react-resize-detector";
import { ScrollableToolbar } from "shared-ts-mui";
import {
  ArraySetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { ValueOption } from "shared-ts-property";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import {
  SizeClass,
  useComponentSizeFromDense,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { useTimeframeProps } from "./useTimeframeProps";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";

export const HistoryPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const withAsSizeClass = useWindowWidth();
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const now = useDateTimeNow();
  const componentSize = useComponentSizeFromDense();
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const {
    buildings,
    selectedBuilding,
    setSelectedBuilding,
    selectedChimney,
    selectedFlue,
    setSelectedFlue,
    gotoNextChimney,
    gotoPreviousChimney,
  } = useMyChimneyContext();

  const {
    flueMetricTimeItems,
    selectedFlueMetricTimeItem,
    handleFlueMetricTimeItemSelect,
  } = useFlueMetricTimeItems(
    "HistoryPage",
    selectedFlue,
    FlueMetricNaturalKeyImpl.ever(selectedFlue.id)
  );

  const timeframeProps = useTimeframeProps({
    flueMetricTimeItem: selectedFlueMetricTimeItem,
    flue: selectedFlue,
    now: now,
  });

  const [diagramFeatures, setDiagramFeatures] =
    useUserSettingWithDefault<DiagramFeaturesType>(
      "HistoryPage.diagramFeatures",
      ArraySetting,
      ["GoodInPercent"]
    );

  const goodInPercentChecked = diagramFeatures.some(
    (it) => it === "GoodInPercent"
  );

  const ignitionByQualityChecked = diagramFeatures.some(
    (it) => it === "IgnitionByQuality"
  );

  const diagramFeaturesValues: Array<
    ValueOption & { checked: boolean; disabled: boolean; icon: ReactElement }
  > = useMemo(() => {
    return [
      {
        id: "GoodInPercent",
        label: t_firefly_shared_domain("Good ignitions in %"),
        checked: goodInPercentChecked,
        disabled: goodInPercentChecked && !ignitionByQualityChecked,
        icon: <TimelineRoundedIcon />,
      },
      {
        id: "IgnitionByQuality",
        label: t_firefly_shared_domain("Ignitions by quality"),
        checked: ignitionByQualityChecked,
        disabled: ignitionByQualityChecked && !goodInPercentChecked,
        icon: <BarChartRoundedIcon />,
      },
    ];
  }, [goodInPercentChecked, ignitionByQualityChecked, t_firefly_shared_domain]);

  const handleBuildingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };
  const handleSelectedFlue = (flueId: FlueId) => {
    setSelectedFlue(flueId);
  };

  const handleDiagramFeaturesToggleGroupChange = (
    event: MouseEvent,
    newValue: Array<string>
  ) => {
    const newDiagramFeatures = newValue as DiagramFeaturesType;
    setDiagramFeatures(newDiagramFeatures);
  };

  return (
    <AppPage
      $key={"HistoryPage"}
      name={t("History")}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Stack
            direction={"column"}
            gap={1}
            useFlexGap
            sx={{ flexGrow: 1 }}
            ref={ref}
          >
            <Toolbar
              disableGutters
              variant={"dense"}
              sx={{ justifyContent: "center" }}
            >
              <BuildingSelect
                buildings={buildings}
                selected={selectedBuilding.id}
                onChange={handleBuildingChange}
              />
              {selectedBuilding.getNumberOfFlues() > 1 && (
                <FlueSelect
                  building={selectedBuilding}
                  selectedFlue={selectedFlue}
                  onSelectFlue={handleSelectedFlue}
                  sx={{ ml: 1 }}
                />
              )}
            </Toolbar>
            <ScrollableToolbar
              ToolbarProps={{
                sx: { justifyContent: "center" },
              }}
            >
              <ToggleButtonGroup
                value={diagramFeatures}
                onChange={handleDiagramFeaturesToggleGroupChange}
              >
                {diagramFeaturesValues.map((diagramFeature) => {
                  return (
                    <TooltipOrNot
                      title={
                        withAsSizeClass === SizeClass.Compact
                          ? diagramFeature.label
                          : undefined
                      }
                    >
                      <ToggleButton
                        color={"primary"}
                        value={diagramFeature.id}
                        key={diagramFeature.id}
                        size={componentSize}
                        sx={{ gap: 1 }}
                      >
                        {diagramFeature.icon}{" "}
                        {withAsSizeClass !== SizeClass.Compact &&
                          diagramFeature.label}
                      </ToggleButton>
                    </TooltipOrNot>
                  );
                })}
              </ToggleButtonGroup>
            </ScrollableToolbar>
            <Toolbar disableGutters sx={{ display: "flex" }}>
              <FlueMetricTimeSelect
                items={flueMetricTimeItems}
                selected={selectedFlueMetricTimeItem}
                onSelect={handleFlueMetricTimeItemSelect}
                color={"inherit"}
                sx={{ flex: 1, maxWidth: width }}
              />
            </Toolbar>
            {timeframeProps != null && (
              <BurnQualityContent
                customerEnvironment={selectedBuilding.environment.id}
                timeframeProps={timeframeProps}
                flue={selectedFlue}
                showBurnQualityCount={ignitionByQualityChecked}
                showGoodInPercent={goodInPercentChecked}
                layout={withAsSizeClass}
              />
            )}
          </Stack>
        </Box>
      }
    />
  );
};

type DiagramFeaturesType =
  | ["IgnitionByQuality", "GoodInPercent"]
  | ["IgnitionByQuality"]
  | ["GoodInPercent"];
