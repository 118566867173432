import { Container, Stack, SxProps } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import { LoginComponentProps, LoginForm } from "@airmont/shared/ts/ui/identity";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { ResponsiveMyChimneyLogo } from "@airmont/firefly/my-chimney/ts/shared";
import { useTranslation } from "react-i18next";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { useSxMerge } from "shared-ts-mui";
import { DeliveredByAirmont } from "@airmont/firefly/my-chimney/ts/pages";

export interface AppLoginProps extends LoginComponentProps {
  sx?: SxProps;
  onRegisterNewUser: () => void;
}

export const LoginPage: FC<AppLoginProps> = (props) => {
  const { onRegisterNewUser } = props;
  const { t } = useTranslation("app");
  const appInfo = useAppInfo();
  const { themeName, setTheme } = useAppTheme();
  const { language, setLanguage } = useLanguage();

  const username = useMemo(
    () => new URLSearchParams(window.location.search).get("username"),
    []
  );

  const handleRegisterNewUser = useCallback(() => {
    onRegisterNewUser();
  }, [onRegisterNewUser]);

  const sx = useSxMerge(props.sx, {
    pt: 2,
    pb: 2,
  });
  return (
    <NavigationLessAppPage
      appName={t(appInfo.name)}
      appIcon={<ResponsiveMyChimneyLogo />}
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      sx={sx}
    >
      <Container
        maxWidth={"xs"}
        style={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          className={"AppLogin"}
          direction={"column"}
          useFlexGap
          gap={2}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            justifyContent: "start",
          }}
        >
          <LoginForm
            {...props}
            onRegisterNewUser={handleRegisterNewUser}
            username={username}
          />
          <DeliveredByAirmont />
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
