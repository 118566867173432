import React, { FC, useMemo, useState } from "react";
import { Building } from "./Building";
import { SimpleTreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SxProps } from "@mui/material";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { BuildingTreeItem } from "./BuildingTreeItem";
import { ChimneyTreeItem } from "./chimney/ChimneyTreeItem";
import { FlueTreeItem } from "./chimney/flue/FlueTreeItem";

export interface BuildingTreeProps {
  building: Building;
  hideBuildingTreeItem?: boolean;
  selectedFlue?: FlueId;
  onSelectFlue?: (flueId: FlueId) => void;
  sx?: SxProps;
}

export const BuildingTreeView: FC<BuildingTreeProps> = (props) => {
  const { building, hideBuildingTreeItem, selectedFlue } = props;
  const allNodeIds = useMemo(() => {
    return ["building"].concat(
      building.chimneys.map((chimney) => `Chimney:${chimney.id}`)
    );
  }, [building]);
  const [expanded] = useState<string[]>(allNodeIds);

  const selected = useMemo(() => {
    if (selectedFlue === undefined) {
      return undefined;
    }

    return `Flue:${selectedFlue}`;
  }, [selectedFlue]);
  const handleNodeSelect = (
    event: React.SyntheticEvent,
    itemId: string | null
  ) => {
    if (itemId?.startsWith("Flue:")) {
      props.onSelectFlue?.(parseFlueId(itemId));
    }
  };

  return (
    <SimpleTreeView
      slots={{
        expandIcon: ChevronRightIcon,
        collapseIcon: ExpandMoreIcon,
      }}
      expandedItems={expanded}
      selectedItems={selected}
      sx={props.sx}
      onSelectedItemsChange={handleNodeSelect}
    >
      {hideBuildingTreeItem === true &&
        building.chimneys.map((chimney) => {
          return (
            <ChimneyTreeItem chimney={chimney}>
              {chimney.flues.map((flue) => {
                return <FlueTreeItem flue={flue} />;
              })}
            </ChimneyTreeItem>
          );
        })}
      {hideBuildingTreeItem !== true && (
        <BuildingTreeItem building={building}>
          {building.chimneys.map((chimney) => {
            return (
              <ChimneyTreeItem chimney={chimney}>
                {chimney.flues.map((flue) => {
                  return <FlueTreeItem flue={flue} />;
                })}
              </ChimneyTreeItem>
            );
          })}
        </BuildingTreeItem>
      )}
    </SimpleTreeView>
  );
};

const parseFlueId = (nodeId: string): FlueId => {
  return nodeId.substring("Flue:".length);
};
