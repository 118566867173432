import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FlueUpdateSpec } from "./FlueUpdate";
import { FireflyBaseDao } from "../../FireflyBaseDao";

export class FlueDao extends FireflyBaseDao {
  private fetchHelper: FetchHelper;

  constructor(environment: string) {
    super("/api/flue/", environment);
    this.fetchHelper = this.createFetchHelper();
  }
  update(update: FlueUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
