import React, { ReactNode, useEffect, useState } from "react";
import { LoginState } from "./LoginState";
import { useIdentityDao } from "./useIdentityDao";
import { UserContext } from "shared-ts-utils-authentication";
import { IdentityUser, IdentityUserDto } from "./IdentityUser";
import { FetchError } from "@airmont/shared/ts/utils/fetch";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface LoginComponentProps {
  loginState: LoginState | undefined;
  onRegisterNewUser: () => void;
  onLoginStateChange: (result: LoginState) => void;
}

export interface LoginProviderProps<U extends IdentityUserDto> {
  loginComponent: React.ElementType<LoginComponentProps>;
  privacyPolicy?: {
    path: string;
    component: React.ElementType;
  };
  registerUser?: {
    path: string;
    component: React.ElementType;
  };
  acceptUserInvitation?: {
    path: string;
    component: React.ElementType;
  };
  confirmUser?: {
    path: string;
    component: React.ElementType;
  };
  resetPassword?: {
    path: string;
    component: React.ElementType;
  };
  authenticationCheck: () => Promise<boolean>;
  userMapper?: (user: U) => IdentityUser;
  children?: ReactNode | ((user: IdentityUser) => ReactNode);
}

export const LoginProvider = <U extends IdentityUserDto>(
  props: LoginProviderProps<U>
) => {
  const {
    loginComponent,
    privacyPolicy,
    registerUser,
    acceptUserInvitation,
    confirmUser,
    resetPassword,
    authenticationCheck,
    userMapper,
  } = props;
  const identityDao = useIdentityDao();
  const [accessChecked, setAccessChecked] = useState<boolean>(false);
  const [accessAuthorized, setAccessAuthorized] = useState<boolean>(false);
  const [loginState, setLoginState] = useState<LoginState | undefined>(
    undefined
  );
  const [user, setUser] = useState<IdentityUser | undefined>(undefined);

  const doRenderPrivacyPolicyRoute =
    privacyPolicy != null
      ? window.location.pathname.startsWith(privacyPolicy?.path)
      : false;
  const doRenderRegisterUser =
    registerUser != null
      ? window.location.pathname.startsWith(registerUser?.path)
      : false;
  const doRenderAcceptUserInvitation =
    acceptUserInvitation != null
      ? window.location.pathname.startsWith(acceptUserInvitation?.path)
      : false;
  const doHandleConfirmUser =
    confirmUser != null
      ? window.location.pathname.startsWith(confirmUser?.path)
      : false;
  const doRenderResetPassword =
    resetPassword != null
      ? window.location.pathname.startsWith(resetPassword?.path)
      : false;

  useEffect(
    function checkAccess() {
      async function checkAccess() {
        try {
          const authenticated = await authenticationCheck();
          setAccessChecked(true);
          setAccessAuthorized(authenticated);
        } catch (e) {
          console.error(
            "LoginProvider: error while checking authentication: ",
            e
          );
          setAccessChecked(true);
        }
      }

      if (!(doRenderRegisterUser || doHandleConfirmUser)) {
        checkAccess();
      }
    },
    [authenticationCheck, doRenderRegisterUser, doHandleConfirmUser]
  );

  useEffect(
    function whenAuthorized() {
      async function whenAuthorized() {
        try {
          const userDto = await identityDao.getUser<U>();
          const user =
            userMapper != null
              ? userMapper(userDto)
              : new IdentityUser(userDto);
          setUser(user);
        } catch (e) {
          console.error("Failed to get logged in user", e);
          if (e instanceof FetchError && e.response?.status === 401) {
            setAccessAuthorized(false);
          }
        }
      }

      if (accessAuthorized) {
        whenAuthorized();
      }
    },
    [accessAuthorized, userMapper, identityDao]
  );

  const handleLogin = (result: LoginState) => {
    if (result === "Success") {
      setAccessAuthorized(true);
    } else {
      setAccessAuthorized(false);
    }
    setLoginState(result);
  };

  const handleRegisterNewUser = () => {
    if (registerUser != null) {
      window.location.href = registerUser?.path;
    }
  };

  if (doRenderResetPassword) {
    const ResetPasswordComponent = resetPassword?.component;
    if (ResetPasswordComponent == null) {
      throw new IllegalStateError("No ResetPasswordComponent configured");
    }
    return <ResetPasswordComponent />;
  }
  if (doRenderPrivacyPolicyRoute) {
    const PrivacyPolicyComponent = privacyPolicy?.component;
    if (PrivacyPolicyComponent == null) {
      throw new IllegalStateError("No PrivacyPolicyComponent configured");
    }
    return <PrivacyPolicyComponent />;
  }
  if (doRenderRegisterUser) {
    const RegisterUserComponent = registerUser?.component;
    if (RegisterUserComponent == null) {
      throw new IllegalStateError("No RegisterUserComponent configured");
    }
    return <RegisterUserComponent />;
  }
  if (doRenderAcceptUserInvitation) {
    const AcceptUserInvitationComponent = acceptUserInvitation?.component;
    if (AcceptUserInvitationComponent == null) {
      throw new IllegalStateError(
        "No AcceptUserInvitationComponent configured"
      );
    }
    return <AcceptUserInvitationComponent />;
  }
  if (doHandleConfirmUser) {
    const ConfirmUserComponent = confirmUser?.component;
    if (ConfirmUserComponent == null) {
      throw new IllegalStateError("No ConfirmUserComponent configured");
    }
    return <ConfirmUserComponent />;
  }

  const LoginComponent = loginComponent;

  if (!accessChecked) {
    return (
      <LoginComponent
        onLoginStateChange={handleLogin}
        onRegisterNewUser={handleRegisterNewUser}
        loginState={"CheckingAccess"}
      />
    );
  } else if (accessChecked && !accessAuthorized) {
    return (
      <LoginComponent
        onLoginStateChange={handleLogin}
        onRegisterNewUser={handleRegisterNewUser}
        loginState={loginState}
      />
    );
  } else if (accessAuthorized && user == null) {
    return (
      <LoginComponent
        onLoginStateChange={handleLogin}
        onRegisterNewUser={handleRegisterNewUser}
        loginState={"LoadingUser"}
      />
    );
  } else if (accessChecked && accessAuthorized && user !== undefined) {
    return (
      <UserContext.Provider value={user}>
        {typeof props.children === "function"
          ? props.children(user)
          : props.children}
      </UserContext.Provider>
    );
  } else {
    return (
      <LoginComponent
        onLoginStateChange={handleLogin}
        onRegisterNewUser={handleRegisterNewUser}
        loginState={undefined}
      />
    );
  }
};
