import { useTranslation } from "react-i18next";
import { ConfirmAccountRequest } from "./ConfirmAccountRequest";
import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useIdentityDao } from "./useIdentityDao";

export const ConfirmUserForm = () => {
  const { t } = useTranslation("shared-ts-ui-identity");
  const identityDao = useIdentityDao();
  const [confirmationSuccess, setConfirmationSuccess] = useState<
    boolean | undefined
  >(undefined);

  useEffect(
    function confirmUserEmail() {
      const doEmailConfirmation = async () => {
        if (identityDao) {
          const params = new URLSearchParams(window.location.search);
          const userId = params.get("userId");
          const code = params.get("code");

          if (userId && code) {
            const request: ConfirmAccountRequest = {
              userId: userId,
              code: code,
            };

            try {
              const success = await identityDao.confirmAccount(request);
              setConfirmationSuccess(success);
            } catch (e) {
              setConfirmationSuccess(false);
            }
          }
        }
      };

      doEmailConfirmation();
    },
    [identityDao]
  );

  return (
    <>
      <Typography variant={"h3"} align={"center"}>
        {confirmationSuccess === undefined && (
          <Typography align={"center"}>
            <CircularProgress color="inherit" />
            {t("Confirming your email")}
            {" ... "}
            {t("please wait")}
          </Typography>
        )}
        {confirmationSuccess === true && (
          <Alert severity={"success"}>
            <AlertTitle>{t("Email confirmed")}</AlertTitle>
            {t("You can now login")}
          </Alert>
        )}
        {confirmationSuccess === false && (
          <Alert severity={"error"}>
            {t("Error while confirming your email")}
          </Alert>
        )}
      </Typography>
      {confirmationSuccess && (
        <Stack
          direction={"row"}
          sx={{ width: "100%", justifyContent: "space-evenly" }}
        >
          <Button
            variant={"contained"}
            onClick={() => window.location.replace("/")}
            sx={{
              marginTop: "30px",
              alignSelf: "center",
            }}
          >
            {t("Log In")}
          </Button>
        </Stack>
      )}
    </>
  );
};
