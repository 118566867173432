import { FireplaceFieldsDto, FlueId } from "@airmont/firefly/shared/ts/domain";
import { SecureEntity, Typed } from "@airmont/firefly/my-chimney/ts/shared";
import { Flue } from "../flue/Flue";

export type FireplaceId = string;

export interface McFireplaceFieldsDto {
  name: string | null;
}

export interface FireplaceDto extends SecureEntity<FlueId>, Typed<"Fireplace"> {
  flueId: FlueId;
  mcFields: McFireplaceFieldsDto | null;
  fields: FireplaceFieldsDto;
}

export interface FireplaceCopyArgs {
  mcFields?: McFireplaceFieldsDto | null;
}

export interface Fireplace
  extends SecureEntity<FireplaceId>,
    Typed<"Fireplace"> {
  mcFields: McFireplaceFieldsDto | null;
  fields: FireplaceFieldsDto;
  flue: Flue | undefined;
  setFlue: (flue: Flue) => void;
  copy: (args: FireplaceCopyArgs) => Fireplace;
}

export class FireplaceImpl implements Fireplace {
  readonly type = "Fireplace";
  readonly id: FireplaceId;
  readonly securityToken: string;
  readonly mcFields: McFireplaceFieldsDto | null;
  readonly fields: FireplaceFieldsDto;
  flue: Flue | undefined;

  constructor(args: {
    id: FireplaceId;
    securityToken: string;
    mcFields: McFireplaceFieldsDto | null;
    fields: FireplaceFieldsDto;
    flue?: Flue;
  }) {
    this.id = args.id;
    this.securityToken = args.securityToken;
    this.mcFields = args.mcFields;
    this.fields = args.fields;
    this.flue = args.flue;
  }

  copy(args: FireplaceCopyArgs): Fireplace {
    return new FireplaceImpl({
      id: this.id,
      securityToken: this.securityToken,
      mcFields: args.mcFields ?? this.mcFields,
      fields: this.fields,
      flue: this.flue,
    });
  }

  setFlue(flue: Flue) {
    this.flue = flue;
  }
}
