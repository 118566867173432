import React, { FC } from "react";
import { Box } from "@mui/material";
import {
  PropertiesCard,
} from "@airmont/shared/ts/ui/properties-card";
import { Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import {
  Flue,
  FlueUpdateSpec,
  useFlueDao,
} from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { LoadingButton } from "@mui/lab";
import {Property} from "shared-ts-property";

export interface FlueFormValues {
  name: string;
}

export interface FlueFormProps {
  environment: CustomerEnvironmentId;
  flue: Flue;
  onChange: (update: FlueUpdateSpec) => void;
}
export const FlueForm: FC<FlueFormProps> = (props) => {
  const { flue, environment } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const flueDao = useFlueDao(environment);
  const formInitialValues: FlueFormValues = {
    name: flue.mcFields?.name ?? "",
  };
  const handleSubmit = async (
    values: FlueFormValues,
    formikHelpers: FormikHelpers<FlueFormValues>
  ) => {
    const update: FlueUpdateSpec = {
      type: "FlueUpdateSpec",
      chimneyId: flue.chimney!.id,
      id: flue.id,
      securityToken: flue.securityToken,
      name: { value: values.name.notEmptyOrNull() },
    };
    await flueDao.update(update);
    props.onChange(update);
  };

  return (
    <Box>
      <Formik
        initialValues={formInitialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          dirty,
          values,
          errors,
          resetForm,
          submitForm,
          isSubmitting,
          setFieldValue,
        }) => {
          const handleSaveClick = () => {
            return submitForm();
          };

          return (
            <Box>
              <PropertiesCard
                elevation={0}
                header={{
                  title: t("Your Settings"),
                }}
              >
                <Property
                  label={tSharedDomain("Name")}
                  name={"name"}
                  value={values?.name}
                  mode={"edit"}
                  helperText={t("Your name on the flue")}
                  onChange={(value, name) => setFieldValue(name, value)}
                />
              </PropertiesCard>
              <LoadingButton
                color={"secondary"}
                variant={"outlined"}
                disabled={!dirty}
                loading={isSubmitting}
                onClick={handleSaveClick}
              >
                {t("Save")}
              </LoadingButton>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
