import React, { FC, useState } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  BurnAssessmentLegend,
  BurnsLoader,
} from "@airmont/firefly/my-chimney/ts/burn";
import { useTimeframe } from "@airmont/shared/ts/ui/timeframe";
import { BurnsPageContent } from "./BurnsPageContent";
import { useTranslation } from "react-i18next";
import { useMyChimneyContext } from "../MyChimneyContext";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import {
  SizeClass,
  useResponsive,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import ExpandRoundedIcon from "@mui/icons-material/ExpandRounded";
import { TimeAxisMaxDuration } from "./TimeAxisMaxDuration";
import { defaultTimeAxisDuration } from "./defaultTimeAxisDuration";
import { Duration } from "luxon";

const halfHourDuration = Duration.fromDurationLike({ minute: 30 });

export const BurnsPage: FC = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const responsive = useResponsive();
  const now = useDateTimeNow();
  const { selectedBuilding, selectedFlue } = useMyChimneyContext();
  const [timeAxisMaxDuration, setTimeAxisMaxDuration] =
    useState<TimeAxisMaxDuration>("default");
  const layoutWidth = useWindowWidth();
  const timeframeProps = useTimeframe({
    disallowFuture: true,
    maxEnd: now.startOf("day"),
    allowedUnits: ["month"],
    persistUsingUserSettings: true,
    storageId: () => "BurnsPage.timeframe",
  });

  return (
    <AppPage
      $key={"BurnsPage"}
      name={t("Burns")}
      hideName
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <BurnsLoader
            environment={selectedBuilding.environment.id}
            flueId={selectedFlue.id}
            fromDate={timeframeProps.timeframe.start}
            toDate={timeframeProps.timeframe.end}
          >
            {({
              burns,
              isAnonymizedTime,
              isBurnsLoading,
              isBurnsFetching,
              isTemperatureSeriesLoading,
              isTemperatureSeriesFetching,
              temperatureSeries,
            }) => {
              return (
                <BurnsPageContent
                  layout={layoutWidth}
                  timeframeProps={timeframeProps}
                  burns={burns}
                  isAnonymizedTime={isAnonymizedTime}
                  isBurnsLoading={isBurnsLoading}
                  isBurnsFetching={isBurnsFetching}
                  temperatureSeries={temperatureSeries}
                  isTemperatureSeriesLoading={isTemperatureSeriesLoading}
                  isTemperatureSeriesFetching={isTemperatureSeriesFetching}
                  timeAxisDomain={{
                    maxDuration: timeAxisMaxDuration,
                  }}
                />
              );
            }}
          </BurnsLoader>
        </Box>
      }
      supportingPaneProps={{
        opacity: 0.3,
        resizePanelProps: {
          minWidth: 320,
          maxWidth: 400,
          vertical: {
            maxHeight: 300,
          },
          mobile: {
            maxHeight: 230,
          },
        },
      }}
      supportingPane={({ toolbarContainer, layout }) => {
        return (
          <Stack
            useFlexGap
            gap={1}
            sx={{
              flexGrow: 1,
              minHeight: 0,
              minWidth: 0,
              alignItems: "stretch",
            }}
          >
            <Toolbar
              variant={responsive.toolbar.variant}
              sx={{
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>{t("Duration of the time axis")}:</Typography>
              <ToggleButtonGroup
                size={responsive.component.size}
                value={timeAxisMaxDuration}
                exclusive
                onChange={(_, value) => setTimeAxisMaxDuration(value)}
              >
                <ToggleButton value={"default"}>
                  <DurationDisplay
                    layout={SizeClass.Compact}
                    duration={defaultTimeAxisDuration}
                    maxUnits={1}
                  />
                </ToggleButton>
                <ToggleButton value={halfHourDuration}>
                  <DurationDisplay
                    layout={SizeClass.Compact}
                    duration={halfHourDuration}
                    maxUnits={1}
                  />
                </ToggleButton>
                <ToggleButton value={"all"}>
                  <ExpandRoundedIcon sx={{ transform: "rotate(90deg)" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Toolbar>
            <Paper sx={{ p: 1 }}>
              <BurnAssessmentLegend layout={layoutWidth} size={"default"} />
            </Paper>
          </Stack>
        );
      }}
    />
  );
};
