import { AppLayout } from "@airmont/shared/ts/ui/app-layout";
import { Action, ActionGroup, RouteItem } from "@airmont/shared/ts/ui/action";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import PaletteRoundedIcon from "@mui/icons-material/PaletteRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import { Outlet } from "react-router-dom";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import React, { ReactNode, useMemo } from "react";
import { Typography, useTheme } from "@mui/material";
import { NotificationContextProvider } from "@airmont/shared/ts/ui/notification";
import RoofingRoundedIcon from "@mui/icons-material/RoofingRounded";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { McUser } from "firefly/my-chimney/ts/user";
import { useNavigation } from "@airmont/shared/ts/ui/navigation";
import { PostHogTrackReactRouterPageView } from "shared-ts-posthog";
import { MyChimneyIcon } from "@airmont/firefly/my-chimney/ts/shared";
import { IntroductionPanel } from "./introduction/IntroductionPanel";
import { useThemeUserSetting } from "@airmont/shared/ts/ui/app-theme-provider";

export const RootPage = () => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const user = useUser<McUser>();
  const [appTheme, setAppTheme] = useThemeUserSetting();
  const [showIntroduction, setShowWelcome] = useUserSettingWithDefault(
    "showIntroduction",
    BooleanSetting,
    true
  );
  const windowWidthComparer = useWindowWidthComparer();
  const appThemePageEnabled = localStorage.getItem("AppThemePage") === "true";
  const { navigationItems, handleSelected } = useNavigation(
    [
      {
        type: "Component",
        render: (navigationType) => (
          <MyChimneyIcon
            mode={theme.palette.mode}
            hideHair={false}
            style={{ minHeight: 92, maxHeight: 200 }}
          />
        ),
      },
      {
        type: "Component",
        render: (navigationType) => (
          <Typography
            variant={"button"}
            align={"center"}
            color={theme.palette.warning.main}
            sx={{
              textTransform: "unset",
              pb: 1,
              fontSize: navigationType === "rail" ? "10px" : undefined,
              fontWeight: navigationType === "rail" ? 500 : undefined,
            }}
          >
            {t("Public Preview")}
          </Typography>
        ),
      },
      {
        type: "RouteItem",
        selectable: true,
        name: t("Overview"),
        route: "/",
        icon:
          windowWidthComparer.sizeClass === SizeClass.Compact ? (
            <MyChimneyIcon
              hideHair
              hideText
              style={{ maxHeight: 24 }}
              mode={theme.palette.mode}
            />
          ) : (
            <RoofingRoundedIcon />
          ),
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        name: t("Burns"),
        route: "/burns",
        icon: <LocalFireDepartmentRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        route: "/history",
        name: t("History"),
        icon: <AnalyticsRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        route: "/guidance",
        name: t("Guide"),
        icon: <LocalLibraryRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        route: "/combustion-plant",
        name: t("Combustion Plant"),
        nameBreakable: t("Combustion Plant:breakable"),
        icon: <SettingsRoundedIcon />,
        selected: false,
      } as RouteItem,
      { type: "Spacer" },
      localStorage.getItem("themePage.enabled") === "true"
        ? ({
            type: "RouteItem",
            selectable: true,
            name: "Theme",
            route: "/theme",
            reference: "/theme",
            icon: <PaletteRoundedIcon />,
          } as RouteItem)
        : undefined,
      {
        type: "ActionGroup",
        icon: <MenuRoundedIcon />,
        preferences: {
          displayInline: true,
        },
        items: [
          {
            type: "RouteItem",
            selectable: true,
            route: "/user",
            name:
              windowWidthComparer.sizeClass === SizeClass.Compact
                ? user.firstName
                : user.fullName,
            icon: <PersonRoundedIcon />,
            selected: false,
          } as RouteItem,
          {
            type: "Action",
            name: appTheme === "dark" ? t("Light") : t("Dark"),
            icon:
              appTheme === "dark" ? (
                <LightModeRoundedIcon />
              ) : (
                <DarkModeRoundedIcon />
              ),
            selected: false,
            onExecute: (item) => {
              setAppTheme(appTheme === "dark" ? "light" : "dark");
            },
          } as Action,
          appThemePageEnabled
            ? ({
                type: "RouteItem",
                selectable: true,
                route: "/app-theme",
                name: "Theme",
                icon: <PaletteRoundedIcon />,
                selected: false,
              } as RouteItem)
            : undefined,
          {
            type: "RouteItem",
            selectable: true,
            route: "/user-feedback",
            name: t("Feedback"),
            icon: <FeedbackRoundedIcon />,
            selected: false,
          } as RouteItem,
          {
            type: "RouteItem",
            selectable: true,
            route: "/about",
            name: `${t("About")} ${t("My Chimney")}`,
            icon: <InfoRoundedIcon />,
            selected: false,
          } as RouteItem,
        ],
      } as ActionGroup,
    ],
    [windowWidthComparer, appTheme, t, theme.palette.mode]
  );

  const overrideMain: ReactNode | undefined = useMemo(() => {
    if (showIntroduction) {
      return <IntroductionPanel onClose={() => setShowWelcome(false)} />;
    }
  }, [setShowWelcome, showIntroduction]);

  return (
    <NotificationContextProvider>
      <AppLayout
        variant={"flat"}
        navigationItems={(navigationType) => {
          if (navigationType === "rail") {
            return navigationItems.filter((it) => !React.isValidElement(it));
          }

          return navigationItems;
        }}
        main={overrideMain ? overrideMain : <Outlet />}
        onNavigationItemSelected={handleSelected}
      />
      <PostHogTrackReactRouterPageView />
    </NotificationContextProvider>
  );
};
