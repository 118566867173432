import { BurnId } from "./Burn";
import {
  DurationDoubleNullableImpl,
  TimeNumberNullable,
  TimeNumberNullableDto,
} from "@airmont/firefly/shared/ts/timeseries";
import { DateTime, Duration, Interval } from "luxon";
import {
  BurnTemperatureSeriesNonSensitive,
  BurnTemperatureSeriesNonSensitiveImpl,
} from "./BurnTemperatureSeriesNonSensitive";

const noDuration = Duration.fromMillis(0);

export interface BurnTemperatureSeriesDto {
  burnId: BurnId;
  dataPoints: Array<TimeNumberNullableDto>;
}

export interface BurnTemperatureSeries {
  burnId: BurnId;
  dataPoints: Array<TimeNumberNullable>;
  duration: Duration;
  maxY: number | undefined;
  minY: number | undefined;
  comparePointValues: (a: number, b: number) => -1 | 0 | 1;

  toNonSensitive(): BurnTemperatureSeriesNonSensitive;
}

export class BurnTemperatureSeriesImpl implements BurnTemperatureSeries {
  readonly burnId: BurnId;
  readonly dataPoints: Array<TimeNumberNullable>;

  constructor(input: { burn: BurnId; dataPoints: Array<TimeNumberNullable> }) {
    this.burnId = input.burn;
    this.dataPoints = input.dataPoints;
  }

  get duration(): Duration {
    if (this.dataPoints.length === 0) {
      return noDuration;
    }

    return Interval.fromDateTimes(
      this.dataPoints[0].time,
      this.dataPoints[this.dataPoints.length - 1].time
    ).toDuration();
  }

  get maxY(): number | undefined {
    if (this.dataPoints.length === 0) {
      return undefined;
    }
    const initialValue = this.dataPoints[0].value ?? 0;
    return this.dataPoints.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value ?? a);
      return comparison === -1 ? b.value ?? 0 : comparison === 1 ? a : a;
    }, initialValue);
  }

  get minY(): number | undefined {
    if (this.dataPoints.length === 0) {
      return undefined;
    }
    const initialValue = this.dataPoints[0].value ?? 0;
    return this.dataPoints.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value ?? 0);
      return comparison === 1 ? b.value ?? 0 : comparison === -1 ? a : a;
    }, initialValue);
  }

  static getMinY(
    timeSeries: Array<BurnTemperatureSeries>,
    minimumMin: number
  ): number {
    return timeSeries.reduce((a, b) => {
      const bMinY = b.minY ?? minimumMin;
      if (bMinY < a) {
        return bMinY;
      } else {
        return a;
      }
    }, minimumMin);
  }

  static getMaxY(
    timeSeries: Array<BurnTemperatureSeries>,
    minimumMax: number
  ): number {
    return timeSeries.reduce((a, b) => {
      const bMaxY = b.maxY ?? minimumMax;
      if (bMaxY > a) {
        return bMaxY;
      } else {
        return a;
      }
    }, minimumMax);
  }

  static getMaxDuration(timeSeries: Array<BurnTemperatureSeries>): Duration {
    return timeSeries.reduce((a, b) => {
      const bDuration = b.duration;
      if (bDuration > a) {
        return bDuration;
      } else {
        return a;
      }
    }, noDuration);
  }

  static fromDto(dto: BurnTemperatureSeriesDto): BurnTemperatureSeriesImpl {
    return new BurnTemperatureSeriesImpl({
      burn: dto.burnId,
      dataPoints: dto.dataPoints.map((it) => {
        return {
          time: DateTime.fromISO(it.time) as DateTime<true>,
          value: it.value,
        };
      }),
    });
  }

  comparePointValues(a: number, b: number): -1 | 0 | 1 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  toNonSensitive(): BurnTemperatureSeriesNonSensitiveImpl {
    const startTime =
      this.dataPoints.length > 0 ? this.dataPoints[0].time : null;
    const dataPoints =
      startTime === null
        ? []
        : this.dataPoints.map((dataPoint) => {
            return new DurationDoubleNullableImpl({
              duration: Interval.fromDateTimes(
                startTime,
                dataPoint.time
              ).toDuration(),
              value: dataPoint.value,
            });
          });

    return new BurnTemperatureSeriesNonSensitiveImpl({
      burn: this.burnId,
      dataPoints: dataPoints,
    });
  }
}
