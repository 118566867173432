import { FC, ReactNode, useEffect, useState } from "react";
import { Card, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { useMetricColors } from "./useMetricColors";
import { keyframes } from "styled-components";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";
import { useSxMerge } from "shared-ts-mui";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

const valueChangeAnimation = (color: string) => keyframes`
  0% {
    color: rgba(${color}, 0.2);
  }
  30% {
    color: rgba(${color}, 0.4);
  }
  70% {
    color: rgba(${color}, 0.8);
  }
  100% {
    color: rgba(${color}, 1);
  }
`;

export interface MetricProps {
  label: string;
  value?: number | string;
  unit?: string;
  tooltip?: ReactNode | undefined;
  startAdornment?: ReactNode;
  layout: SizeClass;
  slotProps?: {
    Card?: {
      sx?: SxProps;
    };
  };
}

export const Metric: FC<MetricProps> = (props) => {
  const theme = useTheme();
  const { backgroundColor, textColor } = useMetricColors();
  const [showValueChangeAnimation, setShowValueChangeAnimation] =
    useState(false);

  const cardSx = useSxMerge(
    { borderColor: theme.palette.divider },
    props.slotProps?.Card?.sx,
    {
      height: props.layout === SizeClass.Compact ? "35px" : "50px",
      borderStyle: "solid",
      borderWidth: "3px",
      borderRadius: "6px",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      gap: 1,
    }
  );

  useEffect(() => {
    setShowValueChangeAnimation(true);
    setTimeout(() => {
      setShowValueChangeAnimation(false);
    }, 1000);
  }, [props.value]);
  return (
    <Stack
      direction={"column"}
      useFlexGap
      gap={props.layout === SizeClass.Compact ? 0.5 : 1}
    >
      <Typography
        variant={props.layout === SizeClass.Compact ? "body2" : undefined}
        align={"center"}
      >
        {props.label}
      </Typography>
      <TooltipOrNot title={props.tooltip}>
        <Card sx={cardSx}>
          {props.startAdornment != null && props.startAdornment}
          <Typography
            className={"value"}
            align={"center"}
            sx={{
              animation: showValueChangeAnimation ? `move 1s ease` : undefined,
              "@keyframes move": {
                "0%": {
                  opacity: 0.2,
                },
                "30%": {
                  opacity: 0.4,
                },
                "70%": {
                  opacity: 0.8,
                },
                "100%": {
                  opacity: 1,
                },
              },
            }}
          >
            {props.value}
          </Typography>
          {props.unit != null && <Typography>{props.unit}</Typography>}
        </Card>
      </TooltipOrNot>
    </Stack>
  );
};
