import { Link, Typography } from "@mui/material";
import React, { FC } from "react";
import { Trans } from "react-i18next";

export interface ContactSupportProps {
  issue: string;
}

export const ContactSupport: FC<ContactSupportProps> = (props) => {
  return (
    <Typography textAlign={"center"} color={"text.secondary"}>
      <Trans
        i18nKey={"This is an application delivered by airMont AS"}
        ns={"firefly-my-chimney-ts-pages"}
        components={{
          Link: <Link href={"https://www.airmont.no"} target={"_blank"} />,
        }}
      />
      {". "}
      <Trans
        i18nKey={
          "If you continue to have issues with {{issue}}, contact us at support@airmont.no"
        }
        values={{
          issue: props.issue,
        }}
        ns={"firefly-my-chimney-ts-pages"}
        components={{
          Link: <Link href={"mailto:support@airmont.no"} target={"_blank"} />,
        }}
      />
    </Typography>
  );
};
