import { TFunction } from "i18next";

export class PasswordValidator {
  private t: TFunction<"shared-ts-ui-identity", undefined>;
  private requiredLength: number;

  public constructor(args: {
    t: TFunction<"shared-ts-ui-identity", undefined>;
    requiredLength: number;
  }) {
    this.t = args.t;
    this.requiredLength = args.requiredLength;
  }

  validate(values: { password: string; confirmPassword: string }): {
    password: string | undefined;
    confirmPassword: string | undefined;
  } {
    if (values.password.isBlank()) {
      return {
        password: this.t("Required"),
        confirmPassword: undefined,
      };
    }
    if (values.password.length < this.requiredLength) {
      return {
        password: this.t("InvalidPassword.mustBeAtLeastOf{{count}}Length", {
          count: this.requiredLength,
        }),
        confirmPassword: undefined,
      };
    }
    if (!/\d/.test(values.password)) {
      return {
        password: this.t("InvalidPassword.digit"),
        confirmPassword: undefined,
      };
    }
    if (!/\p{Lu}/u.test(values.password)) {
      return {
        password: this.t("InvalidPassword.upperCase"),
        confirmPassword: undefined,
      };
    }
    if (!/\p{Ll}/u.test(values.password)) {
      return {
        password: this.t("InvalidPassword.lowerCase"),
        confirmPassword: undefined,
      };
    }
    if (!values.password.hasNonAlphanumeric()) {
      return {
        password: this.t("InvalidPassword.nonAlphaNumeric"),
        confirmPassword: undefined,
      };
    }

    if (values.password !== values.confirmPassword) {
      return {
        password: undefined,
        confirmPassword: this.t(
          "Password and confirmation of password must match"
        ),
      };
    }

    return {
      password: undefined,
      confirmPassword: undefined,
    };
  }
}
