import { useDense } from "./useDense";
import { useWindowSize } from "./useWindowSize";
import { WindowSize } from "./WindowSize";
import { OverridableStringUnion } from "@mui/types";
import { ToolbarPropsVariantOverrides } from "@mui/material/Toolbar/Toolbar";
import { SizeClass } from "./SizeClass";
import { useMemo } from "react";

export type Toolbar_variant = OverridableStringUnion<
  "regular" | "dense",
  ToolbarPropsVariantOverrides
>;

export interface UseResponsiveType {
  dense: boolean;
  window: WindowSize;
  component: {
    size: "small" | "medium" | "large";
  };
  toolbar: {
    variant: Toolbar_variant;
  };
}

export const useResponsive = (): UseResponsiveType => {
  const windowSize = useWindowSize();
  const dense = useDense();

  return useMemo(() => {
    const componentSize =
      windowSize.windowWidth === SizeClass.Compact
        ? "large"
        : windowSize.windowWidth === SizeClass.Medium
        ? "medium"
        : "small";
    return {
      window: windowSize,
      dense: dense,
      component: { size: componentSize },
      toolbar: {
        variant: dense ? "dense" : "regular",
      },
    };
  }, [windowSize, dense]);
};
