import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import React, { FC, useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useIdentityDao } from "./useIdentityDao";
import { LoginState } from "./LoginState";
import { FormikProperty } from "shared-ts-property";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

type LoginFormValues = {
  username: string;
  password: string;
};

export interface LoginFormProps {
  username?: string | null;
  loginState: LoginState | undefined;
  onLoginStateChange: (result: LoginState) => void;
  onRegisterNewUser: () => void;
}

export const LoginForm: FC<LoginFormProps> = (props) => {
  const { username, loginState, onLoginStateChange, onRegisterNewUser } = props;
  const { t } = useTranslation("shared-ts-ui-identity");

  const [sendResetPasswordEmailStatus, setSendResetPasswordEmailStatus] =
    useState<"none" | "sending" | "sent">("none");

  const identityDao = useIdentityDao();

  const initialValues: LoginFormValues = {
    username: username ?? "",
    password: "",
  };

  const handleNewUserClick = useCallback(
    () => onRegisterNewUser(),
    [onRegisterNewUser]
  );
  const handleSubmit = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => {
    setSendResetPasswordEmailStatus("none");
    const result = await identityDao.loginUsingCookies({
      email: values.username,
      password: values.password,
    });
    formikHelpers.setSubmitting(false);
    onLoginStateChange(result);
  };

  const handleForgotPassword = async (values: LoginFormValues) => {
    setSendResetPasswordEmailStatus("sending");
    await identityDao.sendResetPasswordEmail({ email: values.username });
    setSendResetPasswordEmailStatus("sent");
  };

  if (loginState === "CheckingAccess") {
    return (
      <Typography align={"center"}>
        {t("Checking Access")}
        <DotsProgress />
      </Typography>
    );
  }
  if (loginState === "LoadingUser") {
    return (
      <Typography align={"center"}>
        {t("Loading User")}
        <DotsProgress />
      </Typography>
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => {
        const handleLoginClick = () => {
          formik.submitForm();
        };

        const handleForgotPasswordClick = () => {
          handleForgotPassword(formik.values);
        };

        return (
          <Form>
            <PropertiesCard
              header={{
                title: t("Log In.title"),
              }}
              sx={{
                justifyContent: "center",
              }}
            >
              <FormikProperty
                formik={formik}
                name={"username"}
                label={t("Email")}
                mode={"edit"}
                autoComplete={"username"}
                fullWidth
                autoFocus
              />
              <FormikProperty
                formik={formik}
                name={"password"}
                label={t("Password")}
                type={"password"}
                autoComplete={"current-password"}
                mode={"edit"}
                fullWidth
              />
              <Stack
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-evenly" }}
              >
                <LoadingButton
                  color={"secondary"}
                  variant={"contained"}
                  disabled={!formik.dirty}
                  loading={formik.isSubmitting}
                  onClick={handleLoginClick}
                >
                  {t("Log In")}
                </LoadingButton>
              </Stack>
              {loginState === "Unauthorized" &&
                sendResetPasswordEmailStatus !== "sent" && (
                  <Alert severity={"warning"}>
                    {t("Wrong username or password")}
                  </Alert>
                )}
              <Stack
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-evenly" }}
              >
                <Button color={"secondary"} onClick={handleNewUserClick}>
                  {t("New User")}
                </Button>
                <LoadingButton
                  color={"secondary"}
                  variant={"text"}
                  disabled={!formik.dirty}
                  loading={sendResetPasswordEmailStatus === "sending"}
                  onClick={handleForgotPasswordClick}
                >
                  {t("Forgot Password")}
                </LoadingButton>
              </Stack>
              {sendResetPasswordEmailStatus === "sent" && (
                <Alert severity={"info"}>
                  <AlertTitle>
                    {" "}
                    {t("An e-mail for resetting password will be sent to")}
                    {": " + formik.values.username}
                  </AlertTitle>
                  {t("if this e-mail matched an existing user")}
                </Alert>
              )}
            </PropertiesCard>
          </Form>
        );
      }}
    </Formik>
  );
};
