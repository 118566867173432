import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  SxProps,
  useTheme,
} from "@mui/material";
import React, { FC } from "react";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { PrivacyPolicyContent } from "./PrivacyPolicyContent";
import {
  SizeClass,
  useWindowHeightComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { MyChimneyIcon } from "@airmont/firefly/my-chimney/ts/shared";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";

export interface PrivacyPolicyPageProps {
  sx?: SxProps;
}

export const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const theme = useTheme();
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const windowWithComparer = useWindowWidthComparer();
  const windowHeightComparer = useWindowHeightComparer();

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const detailsMaxHeight =
    windowHeightComparer.sizeClass === SizeClass.Compact
      ? "75px"
      : windowHeightComparer.sizeClass === SizeClass.Medium
      ? "250px"
      : windowHeightComparer.sizeClass === SizeClass.Expanded
      ? "450px"
      : "650px";

  return (
    <NavigationLessAppPage
      appName={t(appInfo.name as "My Chimney")}
      appIcon={
        <MyChimneyIcon
          mode={theme.palette.mode}
          color={"#99cccc"}
          style={{ maxHeight: "120px" }}
        />
      }
      flexGrowHeader={1}
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      sx={props.sx}
    >
      <Container
        maxWidth={
          windowWithComparer.sizeClass === "Compact"
            ? "xs"
            : windowWithComparer.sizeClass === "Medium"
            ? "xs"
            : windowWithComparer.sizeClass === "Expanded"
            ? "sm"
            : "md"
        }
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack direction={"column"} useFlexGap gap={2} sx={sx}>
          <Accordion expanded={true}>
            <AccordionSummary
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.light",
              }}
            >
              {t("Privacy Policy")}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                maxHeight: detailsMaxHeight,
                overflowY: "auto",
              }}
            >
              <PrivacyPolicyContent />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
