import React, { FC, MutableRefObject, ReactElement, useState } from "react";
import { Box, SxProps } from "@mui/material";
import {
  publicApiType,
  ScrollMenu as ExtScrollMenu,
} from "react-horizontal-scrolling-menu";
import { LeftArrow } from "./LeftArrow";
import { RightArrow } from "./RightArrow";
import { useSxMerge } from "shared-ts-mui";
import { Property } from "csstype";

export interface ScrollMenuProps {
  scrollToItem?: string;
  children: Array<ReactElement<{ itemId: string }>>;
  ref?: React.Ref<unknown>;
  gap?: Property.Gap<number> | undefined;
  sx?: SxProps;
}

export const ScrollMenu: FC<ScrollMenuProps> = (props) => {
  const dragState = React.useRef(new DragDealer());
  const [api, setApi] = useState<publicApiType>();
  const apiRef = React.useRef<publicApiType>(
    null
  ) as MutableRefObject<publicApiType>;
  const childCount = React.Children.count(props.children);
  const handleDrag = (api: publicApiType) => (ev: React.MouseEvent) =>
    dragState.current.dragMove(ev, (posDiff) => {
      if (api.scrollContainer.current) {
        api.scrollContainer.current.scrollLeft += posDiff;
      }
    });
  const onMouseDown = React.useCallback(
    () => dragState.current.dragStart,
    [dragState]
  );
  const onMouseUp = React.useCallback(
    () => dragState.current.dragStop,
    [dragState]
  );

  React.useEffect(() => {
    // TODO: Check for api with shorter timeout, repeatedly until found
    const id = setTimeout(() => {
      if (apiRef.current != null) {
        setApi(apiRef.current);
      }
    }, 500);

    return () => clearTimeout(id);
    // we only want to scroll upon mount, therefor do not depend upon props.scrollToItem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef]);

  React.useEffect(() => {
    if (api != null && props.scrollToItem != null) {
      const scrollToItem = props.scrollToItem;
      const itemsList = [...api.items.toItems()];
      const itemKey = itemsList.find((el) => el.includes(scrollToItem));
      if (itemKey != null) {
        const item = api.getItemById(itemKey);
        // const item = apiRef.current.getItemByIndex(5) // or by index
        api.scrollToItem(item, "auto", "start");
      }
    }
    // Only scroll once after mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const sx = useSxMerge(props.sx, {
    ".react-horizontal-scrolling-menu--wrapper": {
      display: "flex",
      flexDirection: "column",
      ".react-horizontal-scrolling-menu--header": {
        width: "100%",
      },
      ".react-horizontal-scrolling-menu--inner-wrapper": {
        display: "flex",
        overflowY: "hidden",
        ".react-horizontal-scrolling-menu--arrow-left": {
          ">button": {
            height: "100%",
          },
        },
        ".react-horizontal-scrolling-menu--arrow-right": {
          ">button": {
            height: "100%",
          },
        },
        ".react-horizontal-scrolling-menu--scroll-container": {
          display: "flex",
          height: "max-content",
          overflowY: "hidden",
          position: "relative",
          width: "100%",
          scrollbarWidth: "none",
          gap: props.gap,
        },
      },
    },
  });

  return (
    <Box className={"ScrollMenu"} sx={sx} ref={props.ref}>
      {childCount > 0 && (
        <ExtScrollMenu
          apiRef={apiRef}
          LeftArrow={<LeftArrow />}
          RightArrow={<RightArrow />}
          onWheel={onWheel}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={handleDrag}
        >
          {props.children}
        </ExtScrollMenu>
      )}
    </Box>
  );
};

function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {
  // NOTE: no good standard way to distinguish touchpad scrolling gestures
  // but can assume that gesture will affect X axis, mouse scroll only Y axis
  // of if deltaY too small probably is it touchpad
  const isTouch = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouch) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
}

class DragDealer {
  clicked: boolean;
  dragging: boolean;
  position: number;

  constructor() {
    this.clicked = false;
    this.dragging = false;
    this.position = 0;
  }

  public dragStart = (ev: React.MouseEvent) => {
    this.position = ev.clientX;
    this.clicked = true;
  };

  public dragStop = () => {
    window.requestAnimationFrame(() => {
      this.dragging = false;
      this.clicked = false;
    });
  };

  public dragMove = (ev: React.MouseEvent, cb: (posDiff: number) => void) => {
    const newDiff = this.position - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (this.clicked && movedEnough) {
      this.dragging = true;
    }

    if (this.dragging && movedEnough) {
      this.position = ev.clientX;
      cb(newDiff);
    }
  };
}
