import {
  FormControlLabel,
  Stack,
  Switch,
  SxProps,
  Typography,
} from "@mui/material";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

export interface ConsentBurnTimeIsSavedPropertyProps {
  value: boolean;
  onChange: (name: string, value: boolean) => void;
  sx?: SxProps;
}

export const ConsentBurnTimeIsSavedProperty: FC<
  ConsentBurnTimeIsSavedPropertyProps
> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const handleChange = (value: SyntheticEvent, checked: boolean) => {
    props.onChange("consentBurnTimeIsSaved", checked);
  };
  return (
    <Stack direction={"column"} sx={props.sx}>
      <Typography>{t("I want to see the time of my burns")}</Typography>
      <FormControlLabel
        name={"consentBurnTimeIsSaved"}
        label={t("Consent.consentBurnTimeIsSaved")}
        checked={props.value}
        onChange={handleChange}
        control={<Switch />}
      ></FormControlLabel>
    </Stack>
  );
};
