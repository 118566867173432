import { Button as MuiButton } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import React, { ForwardedRef, forwardRef } from "react";
import { SxProps } from "@mui/system";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { merge } from "lodash";
import { Theme } from "@mui/material/styles";
// TODO: Remove dependency on responsive
export const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const componentSize = useComponentSizeFromDense();
  let sx: SxProps<Theme> | undefined = props.sx;

  const iconButNoText =
    (props.endIcon != null || props.startIcon != null) && !props.children;

  if (iconButNoText) {
    sx = merge({}, sx, {
      minWidth: "unset",
      paddingLeft: "8px",
      paddingRight: "8px",
      ".MuiButton-startIcon": {
        marginLeft: "unset",
        marginRight: "unset",
      },
    } as SxProps);
  }

  return <MuiButton {...props} sx={sx} size={componentSize} ref={ref} />;
});
