import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Stack,
  SxProps,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, FormikErrors } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  AcceptInvitationRequest,
  useIdentityDao,
} from "@airmont/shared/ts/ui/identity";
import { InvitedUserDto } from "./InvitedUserDto";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { Property } from "shared-ts-property";
import { MyChimneyIcon } from "@airmont/firefly/my-chimney/ts/shared";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";

type InvitedUserFormValues = {
  password: string;
  confirmPassword: string;
};

const initialRegisterUserFormValues: InvitedUserFormValues = {
  password: "",
  confirmPassword: "",
};

export interface AcceptUserInvitationProps {
  sx?: SxProps;
}

export const AcceptUserInvitation: FC<AcceptUserInvitationProps> = (props) => {
  const { t } = useTranslation("app");
  const { t: tSharedIdentity } = useTranslation("shared-ts-ui-identity");
  const theme = useTheme();
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const componentSize = useComponentSizeFromDense();
  const userIdentityDao = useIdentityDao();
  const token = useMemo(
    () => new URLSearchParams(window.location.search).get("token"),
    []
  );
  const userId = useMemo(
    () => new URLSearchParams(window.location.search).get("userId"),
    []
  );

  const [invitedUserDto, setInvitedUserDto] = useState<
    InvitedUserDto | undefined
  >(undefined);
  const [resetPasswordToken, SetResetPasswordToken] = useState<
    string | undefined
  >(undefined);

  const [initialFormValues, setInitialFormValues] =
    useState<InvitedUserFormValues>(initialRegisterUserFormValues);

  const [userInvitationComplete, setUserInvitationComplete] = useState<
    boolean | undefined
  >(undefined);

  const [getInvitedUserFailed, setGetInvitedUserFailed] =
    useState<boolean>(false);

  useEffect(
    function getInvitedUser() {
      const doGetInvitedUser = async () => {
        if (userIdentityDao && token != null && userId != null) {
          try {
            const invitedUserResponse =
              await userIdentityDao.getInvitedUser<InvitedUserDto>(
                token,
                userId
              );
            setInvitedUserDto(invitedUserResponse.user);
            SetResetPasswordToken(invitedUserResponse.resetPasswordToken);
            setInitialFormValues({
              password: "",
              confirmPassword: "",
            });
          } catch (e) {
            setGetInvitedUserFailed(true);
          }
        }
      };
      doGetInvitedUser();
    },
    [token, userId, userIdentityDao]
  );

  const handleValidate = (
    values: InvitedUserFormValues
  ): FormikErrors<InvitedUserFormValues> => {
    const errors: FormikErrors<InvitedUserFormValues> = {};

    if (values.password.isBlank()) {
      errors.password = tSharedIdentity("Required");
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = tSharedIdentity(
        "Password and confirmation of password must match"
      );
    }
    return errors;
  };

  const handleSubmit = async (
    values: InvitedUserFormValues,
    formikHelpers: FormikHelpers<InvitedUserFormValues>
  ) => {
    const request: AcceptInvitationRequest = {
      userId:
        invitedUserDto?.id ??
        _throw(new IllegalStateError("invitedUserDto.id was null")),
      resetPasswordToken:
        resetPasswordToken ?? _throw(new IllegalStateError("token is null")),
      password: values?.password,
      confirmedPassword: values?.confirmPassword,
    };
    try {
      await userIdentityDao?.acceptInvitation(request);
      formikHelpers.setSubmitting(false);
      setUserInvitationComplete(true);
    } catch (e) {
      setUserInvitationComplete(false);
    }
  };

  const handleLoginAsActivatedUser = () => {
    window.location.href = `/?username=${invitedUserDto?.userName}`;
  };

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  return (
    <NavigationLessAppPage
      appName={t(appInfo.name)}
      appIcon={
        <MyChimneyIcon
          mode={theme.palette.mode}
          color={"#99cccc"}
          style={{ maxHeight: "120px" }}
        />
      }
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      sx={props.sx}
    >
      <Container
        maxWidth={"xs"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          className={"AppLogin"}
          direction={"column"}
          useFlexGap
          gap={2}
          sx={sx}
        >
          {getInvitedUserFailed && (
            <Alert severity={"warning"} variant={"outlined"}>
              {`${tSharedIdentity(
                "Your invitation token has unfortunately expired"
              )}.`}
            </Alert>
          )}
          {invitedUserDto === undefined && !getInvitedUserFailed && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </div>
          )}
          <>
            {invitedUserDto !== undefined && (
              <>
                <Typography variant={"h3"} align={"center"} sx={{ mb: 2 }}>
                  {t("Welcome") +
                    " " +
                    invitedUserDto?.firstName +
                    " " +
                    invitedUserDto?.lastName}
                </Typography>
                {userInvitationComplete !== true && (
                  <>
                    <Typography>
                      {tSharedIdentity(
                        "You have been invited to use the application"
                      )}{" "}
                      {t("My Chimney")}
                      {". "}
                      {tSharedIdentity(
                        "Your username and e-mail is registered as"
                      )}
                      {": "}
                    </Typography>
                    <Typography>{invitedUserDto?.email}</Typography>
                    <Typography>
                      {tSharedIdentity(
                        "You must provide a password and accept the invitation before you can continue"
                      )}
                      {". "}
                    </Typography>
                    {
                      <Formik
                        initialValues={initialFormValues}
                        validate={handleValidate}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                      >
                        {({
                          dirty,
                          values,
                          submitForm,
                          isSubmitting,
                          setFieldValue,
                          handleBlur,
                          touched,
                          errors,
                        }) => {
                          const handleSubmitClick = () => {
                            submitForm();
                          };

                          const handleBlurOnLastInput = (
                            event: React.FocusEvent
                          ) => {
                            handleBlur(event);
                          };

                          return (
                            <Stack direction={"column"}>
                              <PropertiesCard
                                sx={{
                                  justifyContent: "center",
                                }}
                              >
                                <Property
                                  name={"password"}
                                  label={tSharedIdentity("Password")}
                                  value={values.password}
                                  type={"password"}
                                  onChange={(value, name) =>
                                    setFieldValue(name, value)
                                  }
                                  mode={"edit"}
                                  fullWidth
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  error={
                                    touched.password && errors.password != null
                                  }
                                />
                                <Property
                                  name={"confirmPassword"}
                                  label={tSharedIdentity("Confirm Password")}
                                  value={values.confirmPassword}
                                  type={"password"}
                                  onChange={(value, name) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={handleBlurOnLastInput}
                                  mode={"edit"}
                                  fullWidth
                                  helperText={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                  error={
                                    touched.confirmPassword &&
                                    errors.confirmPassword != null
                                  }
                                />
                              </PropertiesCard>
                              <Toolbar>
                                <LoadingButton
                                  color={"secondary"}
                                  variant={"outlined"}
                                  size={componentSize}
                                  disabled={!dirty}
                                  loading={isSubmitting}
                                  onClick={handleSubmitClick}
                                >
                                  {tSharedIdentity("Accept Invitation")}
                                </LoadingButton>
                              </Toolbar>
                            </Stack>
                          );
                        }}
                      </Formik>
                    }
                  </>
                )}
              </>
            )}
            {userInvitationComplete === true && (
              <>
                <Alert severity={"success"} variant={"outlined"}>
                  {tSharedIdentity("Your user is now activated")}
                </Alert>
                <Typography align={"center"}>
                  {tSharedIdentity("You can now login")}
                </Typography>
                <Button
                  variant={"contained"}
                  size={componentSize}
                  onClick={handleLoginAsActivatedUser}
                >
                  {tSharedIdentity("Login as")} {invitedUserDto?.userName}
                </Button>
              </>
            )}
            {userInvitationComplete === false && (
              <Typography variant={"h3"} align={"center"}>
                {tSharedIdentity("Accept invitation failed")}
              </Typography>
            )}
          </>
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
