import { useMemo } from "react";
import { ResizePanelAllProps } from "./ResizePanelAllProps";
import { merge } from "lodash";
import { ResizePanelProps } from "./ResizePanelProps";

export const useResolveResizePanelProps = (
  layout: "mobile" | "vertical" | "horizontal",
  props: ResizePanelAllProps | undefined
): ResizePanelProps | undefined => {
  const minWidth = props?.minWidth;
  const minHeight = props?.minHeight;
  const maxWidth = props?.maxWidth;
  const maxHeight = props?.maxHeight;
  const style = props?.style;
  const specific = props != null ? props[layout] : undefined;
  return useMemo(() => {
    return merge(
      {
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        style: style,
      },
      specific
    );
  }, [minWidth, maxWidth, minHeight, maxHeight, style, specific]);
};
