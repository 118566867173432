import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { McUser } from "firefly/my-chimney/ts/user";
import { AddressAccessCard } from "./AddressAccessCard";
import { UserSettings } from "./UserSettings";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { useIdentityDao } from "@airmont/shared/ts/ui/identity";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Property } from "shared-ts-property";

export const UserPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const componentSize = useComponentSizeFromDense();
  const identityDao = useIdentityDao();
  const user = useUser<McUser>();
  const handleLogoutClick = async () => {
    await identityDao.logout();
    window.location.pathname = "/";
  };

  return (
    <AppPage
      $key={"User"}
      name={t("User")}
      mainPaneProps={{ scrollableContentVertically: true }}
      mainPane={
        <Box>
          <Container maxWidth={"xs"}>
            <Stack direction={"column"} useFlexGap gap={2}>
              <Stack>
                <CardHeader title={user.fullName}></CardHeader>
                <CardContent sx={{ "&&": { pb: 0 } }}>
                  <PropertiesCard
                    elevation={0}
                    childPropertyProps={{
                      fullWidth: true,
                    }}
                  >
                    <Property
                      name={"username"}
                      label={t("Username")}
                      value={user.name}
                      mode={"read"}
                    />
                  </PropertiesCard>
                </CardContent>
              </Stack>
              <Toolbar
                variant={componentSize === "small" ? "dense" : "regular"}
              >
                <Button
                  size={componentSize}
                  variant={"outlined"}
                  onClick={handleLogoutClick}
                >
                  {t("Logout")}
                </Button>
              </Toolbar>
              <UserSettings user={user} />
              <AddressAccessCard user={user} />
            </Stack>
          </Container>
        </Box>
      }
    />
  );
};
