import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { humanReadableBuildVersion, useAppInfo } from "shared-ts-app-info";
import { AboutPublicPreview } from "./AboutPublicPreview";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { PrivacyPolicyContent } from "../privacyPolicy/PrivacyPolicyContent";
import {
  SizeClass,
  useComponentSizeFromDense,
  useDense,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { notUndef } from "@airmont/shared/ts/utils/core";

export const AboutPage: FC = () => {
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const width = useWindowWidth();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { t: t_shared_ts_release } = useTranslation("shared-ts-release");
  const { t: t_shared_ts_app_info } = useTranslation("shared-ts-app-info");
  const [, setShowWelcome] = useUserSettingWithDefault(
    "showIntroduction",
    BooleanSetting,
    true
  );

  const [publicPreviewInfoIsOpen, setPublicPreviewInfoIsOpen] =
    useUserSettingWithDefault(
      "AboutPage.publicPreviewInfoIsOpen",
      BooleanSetting,
      false
    );
  const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] =
    useUserSettingWithDefault(
      "AboutPage.privacyPolicyIsOpen",
      BooleanSetting,
      false,
      {
        storeLocally: true,
      }
    );
  const appInfo = useAppInfo();
  const handlePublicPreviewAccordionChange = () => {
    setPublicPreviewInfoIsOpen(!publicPreviewInfoIsOpen);
  };

  const handlePrivacyPolicyAccordionChange = () => {
    setPrivacyPolicyIsOpen(!privacyPolicyIsOpen);
  };

  return (
    <AppPage
      $key={"AboutPage"}
      name={`${t("About")} ${t("My Chimney")}`}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Container
          maxWidth={
            width === SizeClass.Compact
              ? "xs"
              : width === SizeClass.Medium
              ? "sm"
              : "md"
          }
        >
          <Card>
            <CardContent>
              <Stack direction={"column"} useFlexGap gap={1}>
                <Typography variant={"h6"}>
                  {t("My Chimney")}
                  {"  ("}
                  {t("Public Preview")}
                  {")"}
                </Typography>
                <Typography>
                  {t_shared_ts_release("Release")}: {appInfo.release}
                </Typography>
                <Typography>
                  {t_shared_ts_app_info("Build Version")}:{" "}
                  {notUndef(appInfo.buildVersion, (it) =>
                    humanReadableBuildVersion(it)
                  ) ?? "?"}
                </Typography>
                <Typography>
                  {t("Made in Halden with Love")}
                  {" ❤️"}
                </Typography>
                <Typography>Copyright © 2024 airMont AS️</Typography>
                <Toolbar disableGutters variant={dense ? "dense" : "regular"}>
                  <Button
                    variant={"outlined"}
                    size={componentSize}
                    onClick={() => setShowWelcome(true)}
                  >
                    {t("Show Introduction")}
                  </Button>
                </Toolbar>
                <Accordion
                  expanded={publicPreviewInfoIsOpen}
                  onChange={handlePublicPreviewAccordionChange}
                  disableGutters
                  variant={"elevation"}
                  elevation={3}
                >
                  <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                    {t("About")} {t("Public Preview")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <AboutPublicPreview />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={privacyPolicyIsOpen}
                  onChange={handlePrivacyPolicyAccordionChange}
                  disableGutters
                  variant={"elevation"}
                  elevation={3}
                >
                  <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                    {t("Privacy Policy")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <PrivacyPolicyContent />
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      }
    />
  );
};
