import React, { FC, useMemo, useState } from "react";
import {
  SizeClass,
  useWindowHeightComparer,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  MobileStepper,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { MascotPeepingFromLeft } from "@airmont/firefly/my-chimney/ts/shared";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { useWindowSize } from "usehooks-ts";

export interface IntroductionPanelProps {
  onClose: () => void;
}

export const IntroductionPanel: FC<IntroductionPanelProps> = (props) => {
  const theme = useTheme();
  const width = useWindowWidth();
  const { height: heightInPx } = useWindowSize();
  const heightComparer = useWindowHeightComparer();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const mascotOpacity =
    width === SizeClass.Compact || heightComparer.lte(SizeClass.Medium) ? 1 : 1;
  const maxSteps = 3;
  const [step, setStep] = useState(1);
  const isLastStep = step <= maxSteps;
  const stepComponents = useMemo(
    () => [
      <Step1 paletteMode={theme.palette.mode} />,
      <Step2 paletteMode={theme.palette.mode} />,
      <Step3 paletteMode={theme.palette.mode} />,
      <Step4 paletteMode={theme.palette.mode} />,
    ],
    [theme.palette.mode]
  );
  const stepComponent = useMemo(() => {
    return stepComponents[step - 1];
  }, [step, stepComponents]);

  const handlePrevStepClick = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  const handleNextStepClick = () => {
    if (step <= maxSteps) {
      setStep(step + 1);
    } else {
      props.onClose();
    }
  };
  return (
    <Box
      className={"WelcomePanel"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        maxWidth={"xs"}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ...(width === SizeClass.Compact && {
            p: 0,
          }),
        }}
      >
        <Box
          sx={{
            mt: 2,
            mb: 2,
            maxHeight: "800px",
            width: "100%",
            flexGrow: 1,
            minHeight: 0,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
            borderRadius: "10px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ...(width === SizeClass.Compact && {
              mt: 0,
              mb: 0,
              borderStyle: "none",
            }),
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                flexGrow: 1,
                minHeight: 0,
                pt: "64px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {stepComponent}
            </Stack>
            <Toolbar sx={{ width: "100%" }} variant={"dense"}>
              <MobileStepper
                variant="dots"
                steps={maxSteps + 1}
                position="static"
                activeStep={step - 1}
                sx={{ flexGrow: 1, borderRadius: "9px" }}
                backButton={
                  <Button
                    size="small"
                    onClick={handlePrevStepClick}
                    disabled={step === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRightRoundedIcon />
                    ) : (
                      <KeyboardArrowLeftRoundedIcon />
                    )}
                    {t("Back")}
                  </Button>
                }
                nextButton={
                  <Button size="small" onClick={handleNextStepClick}>
                    {isLastStep ? t("Next") : t("Close")}
                    {!isLastStep ? undefined : theme.direction === "rtl" ? (
                      <KeyboardArrowLeftRoundedIcon />
                    ) : (
                      <KeyboardArrowRightRoundedIcon />
                    )}
                  </Button>
                }
              />
            </Toolbar>
          </Stack>
          <Stack></Stack>
          <MascotPeepingFromLeft
            mode={theme.palette.mode}
            style={{
              flexGrow: 1,
              minHeight: 0,
              maxHeight: heightInPx < 700 ? "450px" : "520px",
              marginTop: "10px",
              marginBottom: "10px",
              opacity: mascotOpacity,
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};
