import { CSSProperties, FC } from "react";
import { MyChimneyIcon } from "./MyChimneyIcon";
import { useTheme } from "@mui/material";
import { merge } from "lodash";

export interface ResponsiveMyChimneyLogoProps {
  style?: CSSProperties;
}

export const ResponsiveMyChimneyLogo: FC<ResponsiveMyChimneyLogoProps> = (
  props
) => {
  const theme = useTheme();
  const style = merge({ width: "100%", height: "100%" }, props.style);
  return <MyChimneyIcon mode={theme.palette.mode} style={style} />;
};
