import React, { FC } from "react";
import { BurnItem } from "./BurnItem";
import { Burn } from "@airmont/firefly/shared/ts/domain";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { ScrollMenu, ScrollMenuItem } from "shared-ts-scroll-menu";
import { SxProps } from "@mui/material";

export interface BurnListProps {
  burns: Array<Burn> | undefined;
  anonymizedTime: boolean | undefined;
  selectedBurn?: Burn;
  maxWidth: number | undefined;
  onSelected?: (selected: Burn) => void;
  sx?: SxProps;
}

export const BurnList: FC<BurnListProps> = (props) => {
  const { burns, anonymizedTime, selectedBurn, maxWidth } = props;

  const handleSelectedBurn = (burn: Burn) => {
    props.onSelected?.(burn);
  };

  useHotkeys(Key.ArrowUp, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex > 0) {
        const nextBurn = burns[selectedIndex - 1];
        props.onSelected?.(nextBurn);
      }
    }
  });

  useHotkeys(Key.ArrowDown, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex < burns.length - 1) {
        const nextBurn = burns[selectedIndex + 1];
        props.onSelected?.(nextBurn);
      }
    }
  });

  if (burns == null) {
    return null;
  }

  return (
    <ScrollMenu gap={1} scrollToItem={selectedBurn?.id} sx={props.sx}>
      {burns?.map((burn) => {
        const selected = burn.id === selectedBurn?.id;
        return (
          <ScrollMenuItem itemKey={burn.id} key={burn.id}>
            <BurnItem
              burn={burn}
              anonymizedTime={
                anonymizedTime ??
                _throw(
                  new IllegalStateError(
                    "anonymizedTime should not be undefined when burns are not"
                  )
                )
              }
              selected={selected}
              onSelect={handleSelectedBurn}
            />
          </ScrollMenuItem>
        );
      })}
    </ScrollMenu>
  );
};
