import { CombustionPlantColors } from "./CombustionPlantColors";
import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const useCombustionPlantColors = (): CombustionPlantColors => {
  const theme = useTheme();
  return useMemo(() => {
    if (theme.palette.mode === "light") {
      return {
        textColor: theme.palette.text.primary,
        backgroundColor: "#9ACCCC",
      };
    } else {
      return {
        textColor: "rgba(0, 0, 0, 0.87)",
        backgroundColor: "#88b9ba",
      };
    }
  }, [theme]);
};
