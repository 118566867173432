import React from "react";
import {
  publicApiType,
  VisibilityContext,
} from "react-horizontal-scrolling-menu";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Button } from "shared-ts-mui";

export const RightArrow = () => {
  const visibility = React.useContext<publicApiType>(VisibilityContext);

  const disabled = visibility.useRightArrowVisible();
  const handleClick = () => {
    visibility.scrollNext();
  };
  return (
    <Button
      color={"secondary"}
      className={"Arrow RightArrow"}
      variant="outlined"
      startIcon={<ArrowForwardIosRoundedIcon />}
      onClick={handleClick}
      disabled={disabled}
      sx={{ ml: 1, opacity: disabled ? 0.4 : 1 }}
    />
  );
};
