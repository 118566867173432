import { AppInfo } from "shared-ts-app-info";
import { ResponsiveMyChimneyLogo } from "@airmont/firefly/my-chimney/ts/shared";

export const appKey = "ff-mc";
export const appName = "My Chimney";
export const appRelease = "2024.0";

export const appInfo: AppInfo = {
  key: appKey,
  name: appName,
  release: appRelease,
  icon: {
    large: <ResponsiveMyChimneyLogo />,
  },
};
