import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { TimeframeObject } from "@airmont/shared/ts/ui/timeframe";
import { IntervalUtils } from "@airmont/shared/ts/utils/luxon";

export class Month {
  readonly dateTime: DateTime<true>;
  readonly asString: string;
  readonly id: string;

  constructor(args: { dateTime: DateTime }) {
    this.dateTime = args.dateTime;
    this.asString =
      this.dateTime.toISO() ?? _throw(new IllegalStateError("dateTime"));
    this.id = this.dateTime.toMillis().toString();
  }

  static current(): Month {
    return new Month({ dateTime: DateTime.now().startOf("month") });
  }

  toTimeframeObject(): TimeframeObject {
    return IntervalUtils.fromDateTimes(
      this.dateTime,
      this.dateTime.plus({ months: 1 })
    );
  }

  previous(): Month {
    return new Month({ dateTime: this.dateTime.minus({ month: 1 }) });
  }

  next(): Month {
    return new Month({ dateTime: this.dateTime.plus({ month: 1 }) });
  }
}
