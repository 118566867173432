import { ChimneyDto } from "./Chimney";
import {
  AddressDto,
  ChimneyFieldsDto,
  ChimneyHatStringsEnum,
} from "@airmont/firefly/shared/ts/domain";
import { FlueDto } from "./flue/Flue";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface ChimneySpecification {
  name?: string;
  fields?: ChimneyFieldsDto;
  flues: Array<FlueDto>;
}

export interface ChimneysSpecification {
  chimneys: Array<ChimneySpecification>;
}

let chimneyCount = 0;
export const createChimneyMocks = (args: {
  addresses: Array<AddressDto>;
  specifications?: Array<ChimneysSpecification>;
}): Array<ChimneyDto> => {
  const chimneys: Array<ChimneyDto> = [];
  args.addresses.forEach((address, index) => {
    const specification = args.specifications?.[index];
    if (specification == null) {
      throw new IllegalArgumentError(
        "Missing specification for address at index: " + index
      );
    }

    specification.chimneys.forEach((chimneySpecification, chimneyIndex) => {
      chimneys.push({
        type: "Chimney",
        id: `chimney-${++chimneyCount}`,
        securityToken: "c-1",
        mcFields: {
          name: chimneySpecification?.name ?? null,
        },
        fields: {
          hat:
            chimneySpecification.fields?.hat ?? ChimneyHatStringsEnum.Unknown,
        },
        flues: chimneySpecification.flues,
      });
    });
  });

  return chimneys;
};
