import { RandomUtils } from "@airmont/shared/ts/utils/core";
import { FlueDto } from "../flue/Flue";
import { FireplaceDto } from "./Fireplace";
import {
  FireplaceFieldsDto,
  YesNoUnknownStringEnum,
} from "@airmont/firefly/shared/ts/domain";

export interface FireplaceSpecification {
  name?: string;
  fields: FireplaceFieldsDto;
}

export interface FireplacesSpecification {
  fireplaces: Array<FireplaceSpecification>;
}

export const createFireplaceMocks = (args: {
  flues: Array<FlueDto>;
  specifcations: Array<FireplacesSpecification>;
}): Array<FireplaceDto> => {
  const fireplaces: Array<FireplaceDto> = [];
  args.flues.forEach((flue, flueIndex) => {
    const specification = args.specifcations[flueIndex];
    if (specification != null) {
      specification.fireplaces.forEach(
        (fireplaceSpecification, fireplaceIndex) => {
          fireplaces.push({
            type: "Fireplace",
            id: `${flue.id}.fireplace-${fireplaceIndex}`,
            securityToken: "f-1",
            flueId: flue.id,
            mcFields: {
              name: fireplaceSpecification.name ?? null,
            },
            fields: {
              catalytic: fireplaceSpecification.fields.catalytic,
            },
          });
        }
      );
    } else {
      const count = RandomUtils.getRandomInt(1, 3);
      for (let i = 1; i <= count; i++) {
        fireplaces.push({
          type: "Fireplace",
          id: `${flue.id}.fireplace-${i}`,
          securityToken: "f-1",
          flueId: flue.id,
          mcFields: {
            name: `Fireplace#${i}`,
          },
          fields: {
            catalytic: YesNoUnknownStringEnum.No,
          },
        });
      }
    }
  });

  return fireplaces;
};
