import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Stack,
  SxProps,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  SizeClass,
  useDense,
  useWindowHeightComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { useCriiptoVerify } from "@criipto/verify-react";
import {
  PrivacyPolicyContent,
  UserRegistrationPrivacyPolicyContent,
} from "@airmont/firefly/my-chimney/ts/pages";
import { LoadingButton } from "@mui/lab";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { useResizeDetector } from "react-resize-detector";
import { useSxMerge } from "shared-ts-mui";

export interface RegisterUserPrivacyPolicyProps {
  onPrivacyConsentGiven?: () => void;
  onCancel?: () => void;
  sx?: SxProps;
}

export const RegisterUserPrivacyPolicy: FC<RegisterUserPrivacyPolicyProps> = (
  props
) => {
  const appInfo = useAppInfo();
  const dense = useDense();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const { loginWithRedirect } = useCriiptoVerify();
  const { t } = useTranslation("app");
  const { ref: accordionsContainerRef, height: accordionsContainerHeight } =
    useResizeDetector({ handleWidth: false });
  const windowWithComparer = useWindowWidthComparer();
  const windowHeightComparer = useWindowHeightComparer();
  const [displayFullPrivacyPolicy, setDisplayFullPrivacyPolicy] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleConsent = () => {
    setIsSubmitting(true);
    loginWithRedirect();
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const handleShortPrivacyPolicyAccordionChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    setDisplayFullPrivacyPolicy(!expanded);
  };

  const handleFullPrivacyPolicyAccordionChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    setDisplayFullPrivacyPolicy(expanded);
  };

  const sx = useSxMerge(props.sx, {
    pt: 2,
    pb: 2,
  });

  return (
    <NavigationLessAppPage
      appName={t(appInfo.name)}
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      flexGrowHeader={"auto"}
      disableScrollY
      sx={sx}
    >
      <Container
        className={"PrivacyPolicy"}
        maxWidth={
          windowWithComparer.sizeClass === SizeClass.Compact
            ? "xs"
            : windowWithComparer.sizeClass === SizeClass.Medium
            ? "sm"
            : windowWithComparer.sizeClass === SizeClass.Expanded
            ? "md"
            : "lg"
        }
        style={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          direction={"column"}
          useFlexGap
          gap={2}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            justifyContent: "start",
          }}
        >
          <Typography variant={"h4"} align={"center"} sx={{ mb: 2 }}>
            {windowWithComparer.sizeClass === SizeClass.Compact
              ? t("Privacy Policy:breakable")
              : t("Privacy Policy")}
          </Typography>
          <Stack
            ref={accordionsContainerRef}
            sx={{ flexGrow: 1, minHeight: 0 }}
          >
            <Accordion
              expanded={!displayFullPrivacyPolicy}
              onChange={handleShortPrivacyPolicyAccordionChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                {t("In Short")}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: `${
                    (accordionsContainerHeight ?? 100) - (64 + 48 + 40)
                  }px`,
                  overflowY: "auto",
                }}
              >
                <UserRegistrationPrivacyPolicyContent />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={displayFullPrivacyPolicy}
              onChange={handleFullPrivacyPolicyAccordionChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                {windowWithComparer.sizeClass === SizeClass.Compact
                  ? t("Full Privacy Policy:breakable")
                  : t("Full Privacy Policy")}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: `${
                    (accordionsContainerHeight ?? 100) - (64 + 48 + 40)
                  }px`,
                  overflowY: "auto",
                }}
              >
                <PrivacyPolicyContent />
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Toolbar
            variant={dense ? "dense" : "regular"}
            sx={{
              gap: 2,
              justifyContent: "center",
            }}
          >
            <LoadingButton
              color={"secondary"}
              variant={"contained"}
              loading={isSubmitting}
              onClick={handleConsent}
            >
              {t("I Consent")}
            </LoadingButton>
            <Button color={"secondary"} onClick={handleCancel}>
              {t("Cancel")}
            </Button>
          </Toolbar>
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
