import { Link, Typography } from "@mui/material";
import React, { FC } from "react";
import { Trans } from "react-i18next";

export const DeliveredByAirmont: FC = () => {
  return (
    <Typography textAlign={"center"} color={"text.secondary"}>
      <Trans
        i18nKey={"This is an application delivered by airMont AS"}
        ns={"firefly-my-chimney-ts-pages"}
        components={{
          Link: <Link href={"https://www.airmont.no"} target={"_blank"} />,
        }}
      />
      .
    </Typography>
  );
};
