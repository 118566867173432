import { FallbackLng } from "i18next";

export const languages = ["nb-NO", "nn-NO", "en-US"];

export const fallbackLanguages: FallbackLng = {
  "en-US": ["en"],
  "nb-NO": ["no", "en"],
  "nn-NO": ["no", "en"],
};

export const languageResources = [
  {
    namespace: "app",
    loader: import("./.i18n"),
  },
  {
    namespace: "firefly-my-chimney-ts-pages",
    loader: import("@airmont/firefly/my-chimney/ts/pages"),
  },
  {
    namespace: "firefly-my-chimney-ts-building",
    loader: import("@airmont/firefly/my-chimney/ts/building"),
  },
  {
    namespace: "firefly-shared-ts-domain",
    loader: import("@airmont/firefly/shared/ts/domain"),
  },
  {
    namespace: "shared-ts-ui-app-boot",
    loader: import("@airmont/shared/ts/ui/app-boot"),
  },
  {
    namespace: "shared-ts-ui-identity",
    loader: import("@airmont/shared/ts/ui/identity"),
  },
  {
    namespace: "shared-ts-ui-timeframe",
    loader: import("@airmont/shared/ts/ui/timeframe"),
  },
  {
    namespace: "shared-ts-release",
    loader: import("shared-ts-release"),
  },
  {
    namespace: "shared-ts-app-info",
    loader: import("shared-ts-app-info"),
  },
  {
    namespace: "shared-ts-language",
    loader: import("shared-ts-language"),
  },
  {
    namespace: "shared-ts-sentry",
    loader: import("shared-ts-sentry"),
  },
  {
    namespace: "shared-ts-posthog",
    loader: import("shared-ts-posthog"),
  },
  {
    namespace: "shared-ts-mui",
    loader: import("shared-ts-mui"),
  },
];
