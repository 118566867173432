import {FetchHelper} from "@airmont/shared/ts/utils/fetch";
import {ChimneyUpdateSpec} from "./ChimneyUpdate";
import {FireflyBaseDao} from "../FireflyBaseDao";

export class ChimneyDao extends FireflyBaseDao {
  private fetchHelper: FetchHelper;

  constructor(environment: string) {
    super("/api/chimney/", environment);
    this.fetchHelper = this.createFetchHelper();
  }

  update(update: ChimneyUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
