import React, { FC, ReactNode, useEffect } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { PageToolbar } from "./PageToolbar";
import { Button } from "shared-ts-mui";

export interface SupportingPaneHeaderProps {
  label?: string;
  toolbarContent?: ReactNode;
  layout: "vertical" | "horizontal";
  disableResize: boolean;
  onChange: (open: boolean) => void;
  onToolbarContainerMount: (element: Element) => void;
}

export const SupportingPaneHeader: FC<SupportingPaneHeaderProps> = (props) => {
  const { layout, disableResize } = props;
  const componentSize = useComponentSizeFromDense();
  const toolbarContainerRef = React.useRef<Element | null>(null);

  useEffect(() => {
    if (toolbarContainerRef.current != null) {
      props.onToolbarContainerMount(toolbarContainerRef.current);
    }
  }, [toolbarContainerRef, props]);

  const handleToggleOpenClick = () => {
    if (!disableResize) {
      props.onChange?.(false);
    }
  };

  return (
    <PageToolbar
      className={"SupportingPaneHeader"}
      sx={{
        maxWidth: "auto",
      }}
    >
      {disableResize && (
        <Typography
          variant={"subtitle2"}
          color={"secondary"}
          component={"h1"}
          sx={{ ml: 1, p: "4px 5px" }}
        >
          {props.label}
        </Typography>
      )}
      {!disableResize && (
        <Button
          size={componentSize}
          startIcon={
            layout === "horizontal" ? (
              <KeyboardDoubleArrowRightRoundedIcon />
            ) : (
              <KeyboardDoubleArrowDownRoundedIcon />
            )
          }
          onClick={handleToggleOpenClick}
          color={"secondary"}
          sx={{
            alignSelf: "stretch",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {props.label}
        </Button>
      )}
      {(props.toolbarContent != null || toolbarContainerRef) && (
        <Divider
          orientation={"vertical"}
          sx={{
            opacity: 0.6,
            marginLeft: 2,
            marginRight: 2,
          }}
        />
      )}
      <Stack
        direction={"row"}
        className={"toolbarContent"}
        sx={{ "> first-child()": { ml: 1 } }}
      >
        {props.toolbarContent != null && props.toolbarContent}
      </Stack>
      <Box
        className={"toolbarContainer"}
        ref={toolbarContainerRef}
        sx={{
          ml: 1,
          display: "flex",
          alignItems: "center",
          gap: 1,
          "> first-child()": { ml: 1 },
        }}
      ></Box>
    </PageToolbar>
  );
};
