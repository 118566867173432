import { FC, useMemo } from "react";
import { SxProps } from "@mui/material";
import { RegisterUserPrivacyPolicy } from "./RegisterUserPrivacyPolicy";
import { RegisterUserProcess } from "./RegisterUserProcess";
import { CriiptoVerifyProvider } from "@criipto/verify-react";

export interface RegisterUserProps {
  sx?: SxProps;
}

export const RegisterUser: FC<RegisterUserProps> = (props) => {
  const code = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("code");
  }, []);

  const redirectUri = useMemo(() => {
    const loc = window.location;
    return `${loc.protocol}//${loc.hostname}:${
      loc.port === "80" ? "" : loc.port
    }/register-user`;
  }, []);

  const handleCancel = () => {
    window.location.href = "/";
  };

  return (
    <CriiptoVerifyProvider
      domain="identification.airmontcloud.net"
      clientID="urn:my:application:identifier:187756"
      redirectUri={redirectUri}
    >
      {code == null && (
        <RegisterUserPrivacyPolicy sx={props.sx} onCancel={handleCancel} />
      )}
      {code != null && <RegisterUserProcess sx={props.sx} />}
    </CriiptoVerifyProvider>
  );
};
