import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fireplace } from "@airmont/firefly/my-chimney/ts/building";
import { YesNoUnknownStringEnum } from "@airmont/firefly/shared/ts/domain";
import { useCombustionPlantColors } from "./useCombustionPlantColors";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface FireplacePanelProps {
  fireplace: Fireplace;
  layout: SizeClass;
}

export const FireplacePanel: FC<FireplacePanelProps> = (props) => {
  const { fireplace } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { backgroundColor, textColor } = useCombustionPlantColors();
  const theme = useTheme();

  return (
    <Stack direction={"column"} className={"Fireplace"}>
      <Card
        elevation={0}
        variant={"outlined"}
        sx={{
          minWidth: "180px",
          minHeight: props.layout === SizeClass.Compact ? "90px" : "120px",
          backgroundColor: backgroundColor,
          pt: 1,
        }}
      >
        <Typography align={"center"} color={textColor}>
          {t("Fireplace")}
        </Typography>
        <Typography align={"center"} color={textColor}>
          {fireplace.mcFields?.name}
        </Typography>
        <Typography align={"center"} color={textColor}>
          {fireplace.fields.catalytic === YesNoUnknownStringEnum.Yes
            ? t("Catalytic")
            : fireplace.fields.catalytic === YesNoUnknownStringEnum.No
            ? t("Non-Catalytic")
            : t("Unknown")}
        </Typography>
      </Card>
      <Stack direction={"row"} sx={{ justifyContent: "space-around" }}>
        <Box
          sx={{
            height: "12px",
            width: "8px",
            borderColor: theme.palette.divider,
            borderStyle: "solid",
            borderTop: "none",
            borderRadius: "3px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: backgroundColor,
          }}
        ></Box>
        <Box
          sx={{
            height: "12px",
            width: "8px",
            borderColor: theme.palette.divider,
            borderStyle: "solid",
            borderTop: "none",
            borderRadius: "3px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: backgroundColor,
          }}
        ></Box>
      </Stack>
    </Stack>
  );
};
