import { ServerErrorFactory } from "./ServerErrorFactory";
import { FetchError } from "./FetchError";
import { isProblemDetailsDto, ProblemDetailsDto } from "./ProblemDetails";
import { ProblemDetailsFetchError } from "./ProblemDetailsFetchError";

export const problemDetailsAwareServerErrorFactory: ServerErrorFactory = async (
  response,
  request
) => {
  try {
    const responseJson = await response.json();
    let problemDetails: ProblemDetailsDto | undefined;
    if (isProblemDetailsDto(responseJson)) {
      problemDetails = responseJson as ProblemDetailsDto;
    }
    const fetchError =
      problemDetails != null
        ? new ProblemDetailsFetchError({
            response: response,
            request: request,
            problemDetails: problemDetails,
          })
        : new FetchError({ response: response, request: request });
    return Promise.reject(fetchError);
  } catch (errorWhileParsingJson) {
    return Promise.reject(
      new FetchError({ response: response, request: request })
    );
  }
};
