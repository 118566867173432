import React, { FC } from "react";
import {
  ChimneyHatIcon,
  ChimneyHatStringsEnum,
} from "@airmont/firefly/shared/ts/domain";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Chimney } from "@airmont/firefly/my-chimney/ts/building";
import { useCombustionPlantColors } from "./useCombustionPlantColors";
import { useTranslation } from "react-i18next";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface ChimneyIllustrationProps {
  chimney: Chimney;
  layout: SizeClass;
}

export const ChimneyIllustration: FC<ChimneyIllustrationProps> = (props) => {
  const { chimney } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const theme = useTheme();
  const { backgroundColor, textColor } = useCombustionPlantColors();
  const strokeWidth = "2px";
  const strokeWidthDouble = "4px";
  const strokeColor = theme.palette.divider;

  return (
    <Stack
      className={"ChimneyIllustration"}
      direction={"column"}
      sx={{ width: "120px" }}
    >
      <ChimneyHatIcon
        sx={{
          color: strokeColor,
          mb: "4px",
          ml: 1,
          mr: 1,
          strokeWidth: strokeWidthDouble,
          fill: backgroundColor,
          opacity:
            chimney.fields.hat === ChimneyHatStringsEnum.Yes ||
            chimney.fields.hat === ChimneyHatStringsEnum.YesWithFan
              ? 1
              : 0,
        }}
      />
      <Box
        sx={{
          height: props.layout === SizeClass.Compact ? "10px" : "20px",
          borderWidth: strokeWidth,
          borderStyle: "solid",
          borderColor: strokeColor,
          backgroundColor: backgroundColor,
        }}
      />
      <Box
        sx={{
          minHeight: props.layout === SizeClass.Compact ? "70px" : "100px",
          justifySelf: "center",
          borderWidth: strokeWidth,
          borderColor: strokeColor,
          borderLeftStyle: "solid",
          borderRightStyle: "solid",
          borderBottomStyle: "solid",
          ml: 1,
          mr: 1,
          padding: 1,
          backgroundColor: backgroundColor,
        }}
      >
        <Typography align={"center"} color={textColor}>
          {t("Chimney")}
        </Typography>
        <Typography align={"center"} color={textColor}>
          {chimney.mcFields?.name}
        </Typography>
      </Box>
    </Stack>
  );
};
