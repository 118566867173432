import React, { FC, ReactNode } from "react";
import {
  publicApiType,
  VisibilityContext,
} from "react-horizontal-scrolling-menu";
import { Box } from "@mui/material";

export interface ScrollMenuItemProps {
  itemKey: string;
  children: ReactNode;
}

export const ScrollMenuItem: FC<ScrollMenuItemProps> = (props) => {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isVisible = visibility.useIsVisible(props.itemKey, true);

  return (
    <Box
      className={"card ScrollMenuItem"}
      data-cy={props.itemKey}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {props.children}
    </Box>
  );
};
