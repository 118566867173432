import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import {
  BuildingSelect,
  FlueSelect,
} from "@airmont/firefly/my-chimney/ts/building";
import { PeriodSelect } from "./periodSelect/PeriodSelect";
import { BurnInfo } from "./BurnInfo";
import { BurnTimeSeriesChart } from "./BurnTimeSeriesChart";
import {
  AddressId,
  Burn,
  BurnTemperatureSeries,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { useResizeDetector } from "react-resize-detector";
import { SizeClass, SizeClassComparer } from "@airmont/shared/ts/ui/responsive";
import { useMyChimneyContext } from "../MyChimneyContext";
import { TimeframeProps } from "@airmont/shared/ts/ui/timeframe";
import { BurnList } from "./BurnList";
import { TimeAxisMaxDuration } from "./TimeAxisMaxDuration";
import { defaultTimeAxisDuration } from "./defaultTimeAxisDuration";
import { Duration } from "luxon";

export interface BurnsPageContentProps {
  layout: SizeClass;
  timeframeProps: TimeframeProps;
  burns: Array<Burn> | undefined;
  isAnonymizedTime: boolean | undefined;
  isBurnsLoading: boolean;
  isBurnsFetching: boolean;
  temperatureSeries: Array<BurnTemperatureSeries> | undefined;
  isTemperatureSeriesLoading: boolean;
  isTemperatureSeriesFetching: boolean;
  timeAxisDomain: {
    maxDuration: TimeAxisMaxDuration;
  };
}

export const BurnsPageContent: FC<BurnsPageContentProps> = (props) => {
  const {
    timeframeProps,
    burns,
    isAnonymizedTime,
    isBurnsFetching,
    temperatureSeries,
    isTemperatureSeriesFetching,
  } = props;
  const theme = useTheme();
  const layout = props.layout;
  const sizeComparer = new SizeClassComparer(layout);
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const {
    buildings,
    selectedBuilding,
    selectedFlue,
    setSelectedBuilding,
    setSelectedFlue,
  } = useMyChimneyContext();

  const [selectedBurn, setSelectedBurn] = useState<Burn | undefined>(undefined);
  const selectedBurnIds = useMemo(() => {
    return selectedBurn !== undefined ? [selectedBurn.id] : [];
  }, [selectedBurn]);

  const [forceAnonymizeTime, setForceAnonymizeTime] = useState<boolean>(false);
  useEffect(() => {
    if (burns != null && burns.length > 0) {
      setSelectedBurn(burns[0]);
    } else {
      setSelectedBurn(undefined);
    }
  }, [burns]);
  const handleSelectedBurn = (burn: Burn) => {
    setSelectedBurn(burn);
  };
  const handleBuildingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };
  const handleSelectedFlue = (flue: FlueId) => {
    setSelectedFlue(flue);
  };

  const handleAnonymizeBurnsClick = () => {
    setForceAnonymizeTime(!forceAnonymizeTime);
  };

  const selectedTemperatureSeries = useMemo(() => {
    return temperatureSeries?.filter((it) => selectedBurn?.id === it.burnId);
  }, [selectedBurn?.id, temperatureSeries]);

  const timeAxisMaxDuration = useMemo(() => {
    let timeAxisDomainMax: Duration | undefined = undefined;
    if (props.timeAxisDomain.maxDuration === "default") {
      timeAxisDomainMax = defaultTimeAxisDuration;
    } else if (props.timeAxisDomain.maxDuration instanceof Duration) {
      timeAxisDomainMax = props.timeAxisDomain.maxDuration;
    } else if (props.timeAxisDomain.maxDuration === "all") {
      return undefined;
    }
    return timeAxisDomainMax;
  }, [props.timeAxisDomain.maxDuration]);

  return (
    <Stack
      direction={"column"}
      gap={1}
      useFlexGap
      sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      ref={ref}
    >
      <Toolbar
        disableGutters
        variant={"dense"}
        sx={{
          justifyContent: layout === SizeClass.ExtraLarge ? "start" : "center",
        }}
      >
        <BuildingSelect
          buildings={buildings}
          selected={selectedBuilding.id}
          onChange={handleBuildingChange}
        />
        {selectedBuilding.getNumberOfFlues() > 1 && (
          <FlueSelect
            building={selectedBuilding}
            selectedFlue={selectedFlue}
            onSelectFlue={handleSelectedFlue}
            sx={{ ml: 1 }}
          />
        )}
      </Toolbar>
      <PeriodSelect
        timeframe={timeframeProps}
        flue={selectedFlue}
        color={"inherit"}
        sx={{ maxWidth: width ?? 100 - 100, minHeight: "fit-content" }}
      />
      {isBurnsFetching && (
        <LinearProgress color={"secondary"} sx={{ height: "1px" }} />
      )}
      {!isBurnsFetching && <Divider />}
      <BurnList
        burns={burns}
        anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
        maxWidth={width}
        selectedBurn={selectedBurn}
        onSelected={handleSelectedBurn}
      />
      <Divider />
      {selectedBurn !== undefined && (
        <BurnInfo
          burn={selectedBurn}
          anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
          variant={"normal"}
          layout={layout}
          hideDate={sizeComparer.lte(SizeClass.Medium)}
          hideEndDate={sizeComparer.eq(SizeClass.Compact)}
        />
      )}

      <Paper
        sx={{
          flexGrow: 1,
          minHeight: "296px",
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          position: "relative",
          pl: layout !== SizeClass.Compact ? 1 : 0,
          backgroundColor: layout === SizeClass.Compact ? "unset" : undefined,
          boxShadow: layout === SizeClass.Compact ? "unset" : undefined,
        }}
      >
        {isTemperatureSeriesFetching && (
          <LinearProgress
            color={"secondary"}
            sx={{
              height: "2px",
              position: "absolute",
              top: "1px",
              left: theme.shape.borderRadius,
              right: theme.shape.borderRadius,
            }}
          />
        )}
        <BurnTimeSeriesChart
          flue={selectedFlue}
          burnTimeSeries={temperatureSeries}
          anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
          selected={selectedBurnIds}
          selectedMonth={timeframeProps.timeframe.start}
          layout={layout}
          timeAxis={{ maxDuration: timeAxisMaxDuration }}
          sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
        />
      </Paper>
    </Stack>
  );
};
