import {
  BurnQualityCountAggregateByTimeLoader,
  BurnQualityCountByTimeChart,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC } from "react";
import {
  TimeframeObject,
  TimeframeUnit,
} from "@airmont/shared/ts/ui/timeframe";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { useBurnDao } from "@airmont/firefly/my-chimney/ts/burn";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface BurnQualityContentProps {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  timeframeProps: {
    timeframe: TimeframeObject;
    timeframeUnit: TimeframeUnit;
  };
  showGoodInPercent: boolean;
  showBurnQualityCount: boolean;
  layout: SizeClass;
}

export const BurnQualityContent: FC<BurnQualityContentProps> = (props) => {
  const { flue, timeframeProps, layout } = props;
  const burnDao = useBurnDao({
    customerEnvironment: props.customerEnvironment,
  });
  return (
    <BurnQualityCountAggregateByTimeLoader
      flueId={flue.id}
      start={flue.audit.created}
      end={timeframeProps.timeframe.end}
      aggregateBy={timeframeProps.timeframeUnit}
      queryBurnQualityCountAggregate={burnDao.queryBurnQualityCountAggregate}
    >
      {({ data: aggregates, isFetching }) => {
        return (
          <BurnQualityCountByTimeChart
            hideAverageGoodInPercent={false}
            showBurnQualityCount={props.showBurnQualityCount}
            showGoodInPercent={props.showGoodInPercent}
            showRawGoodInPercent={false}
            interval={timeframeProps.timeframe}
            timeUnit={timeframeProps.timeframeUnit}
            aggregates={aggregates}
            thresholdFactor={1}
            loading={isFetching}
            layout={layout}
            sx={{ flexGrow: 1, minHeight: 0 }}
          />
        );
      }}
    </BurnQualityCountAggregateByTimeLoader>
  );
};
