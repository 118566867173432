import { Flue, FlueDto } from "./flue/Flue";
import { Fireplace } from "./fireplace/Fireplace";
import { ChimneyFieldsDto } from "@airmont/firefly/shared/ts/domain";
import { SecureEntity, Typed } from "@airmont/firefly/my-chimney/ts/shared";
import { Flues } from "./Flues";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Building } from "../Building";

export type ChimneyId = string;

export interface McChimneyFieldsDto {
  name: string | null;
}

export interface ChimneyDto extends SecureEntity<ChimneyId>, Typed<"Chimney"> {
  mcFields: McChimneyFieldsDto | null;
  fields: ChimneyFieldsDto;
  flues: Array<FlueDto>;
}

export interface ChimneyCopyArgs {
  mcFields?: McChimneyFieldsDto | null;
  flues?: Array<Flue>;
}

export interface Chimney extends SecureEntity<ChimneyId>, Typed<"Chimney"> {
  building: Building;
  mcFields: McChimneyFieldsDto | null;
  fields: ChimneyFieldsDto;
  flues: Array<Flue>;
  getNext: () => Chimney;
  getPrevious: () => Chimney;
  getFirstFlue: () => Flue;
  getFireplaces: () => Array<Fireplace>;
  copy: (args: ChimneyCopyArgs) => Chimney;
  setBuilding: (building: Building) => void;
}

export class ChimneyImpl implements Chimney {
  readonly type = "Chimney";
  readonly id: ChimneyId;
  readonly securityToken: string;
  readonly mcFields: McChimneyFieldsDto | null;
  readonly fields: ChimneyFieldsDto;

  constructor(args: {
    id: ChimneyId;
    securityToken: string;
    mcFields: McChimneyFieldsDto | null;
    fields: ChimneyFieldsDto;
    flues?: Array<Flue>;
  }) {
    this.id = args.id;
    this.securityToken = args.securityToken;
    this.mcFields = args.mcFields;
    this.fields = args.fields;
    this._flues = new Flues(args.flues ?? []);
  }

  private _building: Building | undefined;

  get building(): Building {
    return (
      this._building ??
      _throw(new IllegalStateError("No building set for Chimney: " + this.id))
    );
  }

  private _flues: Flues;

  get flues(): Array<Flue> {
    return this._flues.flues;
  }

  getNext(): Chimney {
    return this.building.getNextChimney(this);
  }

  getPrevious(): Chimney {
    return this.building.getPreviousChimney(this);
  }

  getFirstFlue(): Flue {
    return this._flues.getFirstFlue();
  }

  copy(args: ChimneyCopyArgs): Chimney {
    const copy = new ChimneyImpl({
      id: this.id,
      securityToken: this.securityToken,
      mcFields: args.mcFields ?? this.mcFields,
      fields: this.fields,
      flues: args.flues ?? this.flues,
    });
    copy.flues.forEach((flue) => {
      flue.setChimney(copy);
    });
    return copy;
  }

  setBuilding(building: Building): Chimney {
    this._building = building;
    return this;
  }

  addFlue(flue: Flue): Chimney {
    flue.setChimney(this);
    this.flues.push(flue);
    return this;
  }

  getFireplaces(): Array<Fireplace> {
    const fireplaces: Array<Fireplace> = [];
    this.flues.forEach((it) => {
      it.fireplaces.forEach((fireplace) => {
        fireplaces.push(fireplace);
      });
    });
    return fireplaces;
  }
}
