import { TimePoint, TimePointDto } from "./TimePoint";
import { IMetaInfo } from "../IMetaInfo";
import { DateTime, Duration, Interval } from "luxon";

const noDuration = Duration.fromMillis(0);

export interface TimeSeriesDto<T = unknown> {
  length: number;
  info: IMetaInfo;
  points: TimePointDto<T>[];
}

export interface TimeSeries<PV = unknown> {
  info: IMetaInfo;
  points: Array<TimePoint<PV>>;
  firstPoint: TimePoint<PV> | undefined;
  lastPoint: TimePoint<PV> | undefined;
  maxY: PV | undefined;
  minY: PV | undefined;
  duration: Duration;
  comparePointValues: (a: PV, b: PV) => -1 | 0 | 1;
}

export class TimeSeriesImpl<PV> implements TimeSeries<PV> {
  readonly info: IMetaInfo;
  readonly points: Array<TimePoint<PV>>;

  constructor(args: {
    info: IMetaInfo;
    readonly points: Array<TimePoint<PV>>;
  }) {
    this.info = args.info;
    this.points = args.points;
  }

  get firstPoint(): TimePoint<PV> | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points[0];
  }

  get lastPoint(): TimePoint<PV> | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points[this.points.length - 1];
  }

  get maxY(): PV | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value);
      return comparison === -1 ? b.value : comparison === 1 ? a : a;
    }, this.points[0].value);
  }

  get minY(): PV | undefined {
    if (this.points.length === 0) {
      return undefined;
    }
    return this.points.reduce((a, b) => {
      const comparison = this.comparePointValues(a, b.value);
      return comparison === 1 ? b.value : comparison === -1 ? a : a;
    }, this.points[0].value);
  }

  get duration(): Duration {
    if (this.points.length <= 1) {
      return noDuration;
    }
    return Interval.fromDateTimes(
      this.points[0].time,
      this.points[1].time
    ).toDuration();
  }

  static fromDto<PV>(dto: TimeSeriesDto<PV>): TimeSeries<PV> {
    return new TimeSeriesImpl({
      info: dto.info,
      points: dto.points.map((point) => {
        return {
          time: DateTime.fromISO(point.time) as DateTime<true>,
          value: point.value,
        };
      }),
    });
  }

  static getMaxDuration<PV>(timeSeries: Array<TimeSeries<PV>>): Duration {
    return timeSeries.reduce((a, b) => {
      const bDuration = b.duration;
      if (bDuration > a) {
        return bDuration;
      } else {
        return a;
      }
    }, noDuration);
  }

  static getLastTime<PV>(
    timeSeries: Array<TimeSeries<PV>>
  ): DateTime<true> | undefined {
    if (timeSeries.length === 0) {
      return undefined;
    }

    return timeSeries.reduce<DateTime<true> | undefined>((a, bTs) => {
      const b = bTs.lastPoint?.time;
      if (b == null) {
        return a;
      }
      if (a == null) {
        return b;
      }

      if (b > a) {
        return b;
      } else {
        return a;
      }
    }, undefined);
  }

  static getMaxY<PV>(timeSeries: Array<TimeSeries<PV>>, minimumMax: PV): PV {
    return timeSeries.reduce((a, b) => {
      const bMaxY = b.maxY ?? minimumMax;
      if (bMaxY > a) {
        return bMaxY;
      } else {
        return a;
      }
    }, minimumMax);
  }

  static getMinY<PV>(timeSeries: Array<TimeSeries<PV>>, minimumMin: PV): PV {
    return timeSeries.reduce((a, b) => {
      const bMinY = b.minY ?? minimumMin;
      if (bMinY < a) {
        return bMinY;
      } else {
        return a;
      }
    }, minimumMin);
  }

  comparePointValues(a: PV, b: PV): -1 | 0 | 1 {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
}
