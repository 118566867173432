import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  Stack,
  SxProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikErrors } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCriiptoVerify } from "@criipto/verify-react";
import { useUserRegistrationDao } from "./useUserRegistrationDao";
import { UserRegisterRequest } from "./UserRegisterRequest";
import { LoadingButton } from "@mui/lab";
import { RegisteringUserDto } from "./RegisteringUser";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import {
  useComponentSizeFromDense,
  useDense,
} from "@airmont/shared/ts/ui/responsive";
import {
  isValidEmail,
  PasswordValidator,
} from "@airmont/shared/ts/ui/identity";
import {
  ConsentBurnTimeIsSavedProperty,
  ContactSupport,
} from "@airmont/firefly/my-chimney/ts/pages";
import { FormikProperty } from "shared-ts-property";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";
import { useLanguage } from "shared-ts-language";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { useSxMerge } from "shared-ts-mui";
import { ProblemDetailsFetchError } from "@airmont/shared/ts/utils/fetch";

type RegisterUserFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  consentBurnTimeIsSaved: boolean;
};

const initialRegisterUserFormValues: RegisterUserFormValues = {
  email: "",
  password: "",
  confirmPassword: "",
  consentBurnTimeIsSaved: true,
};

export interface RegisterUserProcessProps {
  sx?: SxProps;
}

export const RegisterUserProcess: FC<RegisterUserProcessProps> = (props) => {
  const dense = useDense();
  const componentSize = useComponentSizeFromDense();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const { t } = useTranslation("app");
  const { t: tSharedIdentity } = useTranslation("shared-ts-ui-identity");
  const {
    result: criiptoLoginResult,
    isLoading: isTokenLoading,
    isInitializing: isTokenInitializing,
  } = useCriiptoVerify();
  const token = (criiptoLoginResult as any)?.id_token;
  const userRegistrationDao = useUserRegistrationDao(token);

  const [registeringUser, setRegisteringUser] = useState<
    RegisteringUserDto | undefined
  >(undefined);

  const [initialFormValues, setInitialFormValues] =
    useState<RegisterUserFormValues>(initialRegisterUserFormValues);

  const [registrationSuccess, setRegistrationSuccess] = useState<
    boolean | undefined
  >(undefined);

  const [registrationFailureMessage, setRegistrationFailureMessage] = useState<
    string | undefined
  >(undefined);

  const [getRegisteringUserFailed, setGetRegisteringUserFailed] =
    useState<boolean>(false);

  const [getRegisteringUserRetryCount, setGetRegisteringUserRetryCount] =
    useState<number>(0);

  const [isFetchingRegisteringUser, setIsFetchingRegisteringUser] =
    useState<boolean>(false);

  useEffect(
    function getRegisteringUser() {
      const doGetRegisteringUser = async () => {
        if (userRegistrationDao) {
          try {
            setIsFetchingRegisteringUser(true);
            const registeringUser =
              await userRegistrationDao.getRegisteringUser();
            setIsFetchingRegisteringUser(false);
            setRegisteringUser(registeringUser);
            setInitialFormValues({
              email: registeringUser.email ?? "",
              password: "",
              confirmPassword: "",
              consentBurnTimeIsSaved: true,
            });
          } catch (e) {
            setIsFetchingRegisteringUser(false);
            setGetRegisteringUserFailed(true);
          }
        }
      };
      doGetRegisteringUser();
    },
    [getRegisteringUserRetryCount, userRegistrationDao]
  );

  const handleValidate = (
    values: RegisterUserFormValues
  ): FormikErrors<RegisterUserFormValues> => {
    const errors: FormikErrors<RegisterUserFormValues> = {};

    if (isValidEmail(values.email)) {
      errors.email = tSharedIdentity("Invalid e-mail address");
    }

    const passwordValidationResult = new PasswordValidator({
      t: tSharedIdentity,
      requiredLength: 6,
    }).validate(values);
    if (passwordValidationResult.password != null) {
      errors.password = passwordValidationResult.password;
    }
    if (passwordValidationResult.confirmPassword != null) {
      errors.confirmPassword = passwordValidationResult.confirmPassword;
    }
    return errors;
  };

  const handleSubmit = async (
    values: RegisterUserFormValues,
    formikHelpers: FormikHelpers<RegisterUserFormValues>
  ) => {
    if (registeringUser == null) {
      throw new IllegalStateError("registeringUser was null");
    }
    const request: UserRegisterRequest = {
      email: values.email,
      password: values.password,
      consentBurnTimeIsSaved: values.consentBurnTimeIsSaved,
    };
    try {
      const mcUser = await userRegistrationDao?.registerUser(request);
      formikHelpers.setSubmitting(false);
      if (mcUser !== undefined) {
        setRegistrationSuccess(true);
      } else {
        // TODO: What to do ???
      }
    } catch (e) {
      console.error(e);
      if (e instanceof ProblemDetailsFetchError) {
        if (e.problemDetails.detail?.includes("DuplicateUserName")) {
          setRegistrationFailureMessage(
            tSharedIdentity("A user with this username is already registered")
          );
        } else {
          setRegistrationFailureMessage(e.problemDetails.detail);
        }
      }
      setRegistrationSuccess(false);
    }
  };

  const handleRetryGetRegisteredUser = () => {
    setGetRegisteringUserRetryCount((prevState) => prevState + 1);
  };

  const handleTokenExpiredTryAgain = () => {
    window.location.href = "/";
  };

  const handleCancel = () => {
    window.location.href = "/";
  };

  const sx = useSxMerge(props.sx, {
    pt: 2,
    pb: 2,
  });

  return (
    <NavigationLessAppPage
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      flexGrowHeader={"auto"}
      sx={sx}
    >
      <Container
        maxWidth={"xs"}
        style={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          className={"RegisterUser"}
          direction={"column"}
          useFlexGap
          gap={2}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            justifyContent: "start",
          }}
        >
          {(isTokenInitializing ||
            isTokenLoading ||
            isFetchingRegisteringUser) && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </div>
          )}
          <>
            {token !== undefined && (
              <>
                {registeringUser && (
                  <>
                    <Typography variant={"h4"} align={"center"}>
                      {t("Welcome to My Chimney")}
                    </Typography>
                    <Typography variant={"h5"} align={"center"} sx={{ mb: 2 }}>
                      {registeringUser?.firstName +
                        " " +
                        registeringUser?.lastName}
                    </Typography>
                  </>
                )}
                {registrationSuccess !== true && (
                  <Formik
                    initialValues={initialFormValues}
                    validate={handleValidate}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => {
                      const handleSubmitClick = () => {
                        formik.submitForm();
                      };

                      return (
                        <Form>
                          <Stack
                            direction={"column"}
                            useFlexGap
                            sx={{ gap: 1 }}
                          >
                            <PropertiesCard
                              header={{
                                title: tSharedIdentity(
                                  "Register yourself as a user"
                                ),
                              }}
                              sx={{
                                justifyContent: "center",
                              }}
                            >
                              <FormikProperty
                                formik={formik}
                                name={"email"}
                                label={tSharedIdentity("Email")}
                                inputProps={{
                                  autocomplete: "off",
                                }}
                                mode={"edit"}
                                fullWidth
                                autoFocus
                              />
                              <FormikProperty
                                formik={formik}
                                name={"password"}
                                label={tSharedIdentity("Password")}
                                type={"password"}
                                autoComplete={"new-password"}
                                mode={"edit"}
                                fullWidth
                              />
                              <FormikProperty
                                formik={formik}
                                name={"confirmPassword"}
                                label={tSharedIdentity("Confirm Password")}
                                type={"password"}
                                autoComplete={"new-password"}
                                mode={"edit"}
                                fullWidth
                              />
                              <ConsentBurnTimeIsSavedProperty
                                value={formik.values.consentBurnTimeIsSaved}
                                onChange={formik.setFieldValue}
                              />
                            </PropertiesCard>
                            <Toolbar
                              variant={dense ? "dense" : "regular"}
                              disableGutters
                              sx={{ gap: 2 }}
                            >
                              <LoadingButton
                                color={"secondary"}
                                variant={"contained"}
                                disabled={!formik.dirty || !formik.isValid}
                                loading={formik.isSubmitting}
                                onClick={handleSubmitClick}
                              >
                                {tSharedIdentity("Register")}
                              </LoadingButton>
                              <Button onClick={handleCancel}>
                                {t("Cancel")}
                              </Button>
                            </Toolbar>
                          </Stack>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </>
            )}
            {registrationSuccess === true && (
              <>
                <Typography variant={"h5"} align={"center"}>
                  {tSharedIdentity("Your user is registered")}
                </Typography>
                <Typography variant={"h6"} align={"center"}>
                  {tSharedIdentity(
                    "Confirmation of your e-email is needed before you can login"
                  )}
                  {". "}
                  {tSharedIdentity(
                    "Please check your e-mail for further instructions"
                  )}
                </Typography>
                <Alert severity={"info"} variant={"outlined"}>
                  {tSharedIdentity("You can safely close this page")}
                </Alert>
              </>
            )}
            {getRegisteringUserFailed && (
              <>
                <Alert severity={"error"}>
                  {`${tSharedIdentity(
                    "Sorry, we could not retrieve your user details"
                  )}. ${tSharedIdentity("Please try again")}.`}
                </Alert>
                <Button
                  variant={"contained"}
                  size={componentSize}
                  onClick={handleRetryGetRegisteredUser}
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  {tSharedIdentity("Try again")}
                </Button>
              </>
            )}
            {registrationSuccess === false && (
              <Alert severity={"error"}>
                <AlertTitle>
                  {tSharedIdentity("User registration failed")}
                </AlertTitle>
                {registrationFailureMessage != null &&
                  registrationFailureMessage}
              </Alert>
            )}
            {token === undefined &&
              !(isTokenInitializing || isTokenLoading) && (
                <>
                  <Alert severity={"error"} variant={"outlined"}>
                    {tSharedIdentity("Token for registration has expired")}
                  </Alert>
                  <Stack
                    direction={"row"}
                    sx={{ width: "100%", justifyContent: "space-evenly" }}
                  >
                    <Button
                      size={componentSize}
                      variant={"contained"}
                      onClick={handleTokenExpiredTryAgain}
                    >
                      {tSharedIdentity("Try again")}
                    </Button>
                  </Stack>
                </>
              )}
            {registrationSuccess === false && (
              <ContactSupport
                issue={tSharedIdentity("Register a user").toLowerCase()}
              />
            )}
          </>
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
