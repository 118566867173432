import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FireplaceUpdateSpec } from "./FireplaceUpdate";
import { FireflyBaseDao } from "../../FireflyBaseDao";

export class FireplaceDao extends FireflyBaseDao {
  private fetchHelper: FetchHelper;

  constructor(environment: string) {
    super("/api/fireplace/", environment);
    this.fetchHelper = this.createFetchHelper();
  }

  update(update: FireplaceUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
