import { McUser } from "firefly/my-chimney/ts/user";
import { FC } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export interface AddressAccessCardProps {
  user: McUser;
}

export const AddressAccessCard: FC<AddressAccessCardProps> = (props) => {
  const { user } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  return (
    <Card variant={"outlined"}>
      <CardHeader
        subheader={t("You have access to the following addresses")}
      ></CardHeader>
      <CardContent>
        <Stack direction={"column"} useFlexGap gap={1}>
          {user.addresses.map((address) => {
            return (
              <Card variant={"outlined"} key={address.id}>
                <CardContent>
                  <Typography>
                    {t("Municipality")}
                    {": "}
                    {address.municipalityId}
                  </Typography>
                  <Typography>{address.streetAddress.asString}</Typography>
                  <Typography>{address.postalCode}</Typography>
                  {address.cadastralNumbers?.cadastralUnit != null && (
                    <Typography>
                      {address.cadastralNumbers.cadastralUnit} /{" "}
                      {address.cadastralNumbers.propertyUnit} /{" "}
                      {address.cadastralNumbers.leaseNumber}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
