import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
export class FireflyBaseDao {
  basePath: string;
  environment: string;
  constructor(basePath: string, environment: string) {
    this.basePath = basePath;
    this.environment = environment;
  }

  protected createFetchHelper(): FetchHelper {
    return new FetchHelper({ basePath: this.basePath, init: { headers: [["Firefly-Environment", this.environment]]}});
  }
}
