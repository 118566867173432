import { StepProps } from "./StepProps";
import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Typography } from "@mui/material";
import {
  BurnAssessment,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import { darkModeTextShadow } from "./darkMode_textShadow";

export const Step1: FC<StepProps> = (props) => {
  const { paletteMode } = props;
  const BurnAssessmentExcellentIcon = resolveBurnAssessmentIcon(
    BurnAssessment.Good
  );
  return (
    <>
      <Typography
        variant="h2"
        align="center"
        sx={{
          fontWeight: 400,
          maxWidth: "400px",
          textShadow: paletteMode === "dark" ? darkModeTextShadow : undefined,
        }}
      >
        <Trans
          i18nKey={"Introduction.welcome"}
          ns={"firefly-my-chimney-ts-pages"}
          components={{ b: <strong /> }}
        />
      </Typography>
      <Typography
        variant="h5"
        align="center"
        sx={{
          mt: 2,
          mb: 2,
          maxWidth: "250px",
          borderRadius: 3,
          textShadow: paletteMode === "dark" ? darkModeTextShadow : undefined,
          p: 1,
        }}
      >
        <Trans
          i18nKey={"Introduction.step.1"}
          ns={"firefly-my-chimney-ts-pages"}
          components={{ b: <strong /> }}
        />
      </Typography>
    </>
  );
};
