import { Container, Stack, SxProps, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { SetNewPasswordForm } from "@airmont/shared/ts/ui/identity";
import { MyChimneyIcon } from "@airmont/firefly/my-chimney/ts/shared";
import { useAppInfo } from "shared-ts-app-info";
import { useLanguage } from "shared-ts-language";
import { useAppTheme } from "@airmont/shared/ts/ui/app-theme-provider";
import { NavigationLessAppPage } from "@airmont/shared/ts/ui/app-boot";

export interface ResetPasswordProps {
  sx?: SxProps;
}

export const SetNewPassword: FC<ResetPasswordProps> = (props) => {
  const { t } = useTranslation("app");
  const theme = useTheme();
  const appInfo = useAppInfo();
  const { language, setLanguage } = useLanguage();
  const { themeName, setTheme } = useAppTheme();
  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  return (
    <NavigationLessAppPage
      appName={t(appInfo.name)}
      appIcon={
        <MyChimneyIcon
          mode={theme.palette.mode}
          color={"#99cccc"}
          style={{ maxHeight: "120px" }}
        />
      }
      language={language}
      onLanguageChange={setLanguage}
      themeName={themeName}
      onThemeChange={setTheme}
      sx={props.sx}
    >
      <Container
        maxWidth={"xs"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Stack
          className={"ResetPassword"}
          direction={"column"}
          useFlexGap
          gap={2}
          sx={sx}
        >
          <SetNewPasswordForm />
        </Stack>
      </Container>
    </NavigationLessAppPage>
  );
};
