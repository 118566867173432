import React, { FC, useEffect } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";

export interface SwipeableSupportingPaneHeaderProps {
  label: string;
  hideLabel?: boolean;
  disableResize: boolean;
  onChange: (open: boolean) => void;
  onToolbarContainerMount: (element: Element) => void;
  sx?: SxProps;
}

export const SwipeableSupportingPaneHeader: FC<
  SwipeableSupportingPaneHeaderProps
> = (props) => {
  const hideLabel = props.hideLabel ?? false;
  const theme = useTheme();
  const toolbarContainerRef = React.useRef<Element | null>(null);

  useEffect(() => {
    if (toolbarContainerRef.current != null) {
      props.onToolbarContainerMount(toolbarContainerRef.current);
    }
  }, [toolbarContainerRef, props]);

  const handleClick = () => {
    if (!props.disableResize) {
      props.onChange?.(false);
    }
  };

  return (
    <Stack
      className={"SwipeableSupportingPaneHeader"}
      direction={"column"}
      onClick={handleClick}
      sx={props.sx}
    >
      {!hideLabel && (
        <Typography color={"secondary.main"} align={"center"}>
          {props.label}
        </Typography>
      )}
      <Stack
        className={"toolbar"}
        direction={"row"}
        sx={{
          width: "100%",
        }}
      >
        <Box
          className={"toolbarContainer"}
          ref={toolbarContainerRef}
          sx={{
            flexGrow: 1,
            ml: 1,
            mr: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
            "> first-child()": { ml: 1 },
            "> :not(:empty)": {
              height: "56px",
              flexGrow: 1,
              borderBottom: "1px solid " + theme.palette.divider,
            },
          }}
        ></Box>
      </Stack>
    </Stack>
  );
};
