import React, { cloneElement, FC, ReactNode } from "react";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";
import { CycleButtonProps } from "./CycleButton";
import { Button_variant } from "../types/muiTypes";
import { SxProps } from "@mui/material";

export interface CycleButtonGroupProps {
  value: string;
  variant?: Button_variant;
  onChange: (value: string) => void;
  children: Array<React.ReactElement<CycleButtonProps>>;
  sx?: SxProps;
}

export const CycleButtonGroup: FC<CycleButtonGroupProps> = (props) => {
  const childrenHasValue = props.children.some((it) => it.props.value != null);
  const active = childrenHasValue
    ? props.children.find((it) => {
        return it.props.value === props.value;
      })
    : typeof props.value === "number"
    ? props.children[props.value]
    : _throw(
        new IllegalArgumentError(
          "value must be a number when children does not have value"
        )
      );

  const resolveNext = () => {
    const currIndex = props.children.findIndex(
      (it) => it.props.value === props.value
    );
    if (currIndex === props.children.length - 1) {
      return props.children[0].props?.value ?? 0;
    } else {
      return props.children[currIndex + 1].props?.value ?? currIndex + 1;
    }
  };

  const handleClick = (
    element: React.ReactElement<{ value?: string; icon?: ReactNode }>
  ) => {
    const nextValue = resolveNext();
    props.onChange(nextValue);
  };
  if (active == null) {
    throw new IllegalArgumentError("Could not resolve active CycleButton");
  }
  return cloneElement(active as any, {
    onClick: (() => {
      handleClick(active);
    }) as any,
    sx: props.sx,
  });
};
