import { FC, useMemo } from "react";
import { SxProps } from "@mui/material";
import { MonthItem } from "./MonthItem";
import { Month } from "./Month";
import { merge } from "lodash";
import { TimeframeProps } from "@airmont/shared/ts/ui/timeframe";
import { Button_color } from "shared-ts-mui";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { FlueMetricsTimeUnitEnum } from "@airmont/firefly/shared/ts/domain";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { DateTime } from "luxon";
import { TimeWheel, useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { ScrollMenu, ScrollMenuItem } from "shared-ts-scroll-menu";

export interface PeriodSelectProps {
  timeframe: TimeframeProps;
  flue: Flue;
  color?: Button_color;
  sx?: SxProps;
}

export const PeriodSelect: FC<PeriodSelectProps> = (props) => {
  const { flue } = props;
  const now = useDateTimeNow();
  const months = useMemo(() => {
    const monthsWithMetrics = flue.metrics
      .filter((it) => it.timeUnit === FlueMetricsTimeUnitEnum.Month)
      .map((it) => {
        return new Month({ dateTime: it.time as DateTime<true> });
      });
    const firstMetricTime =
      monthsWithMetrics[monthsWithMetrics.length - 1].dateTime;
    const allMonths: Array<Month> = [];
    new TimeWheel({ start: firstMetricTime, timeUnit: "month" }).runUntilTime(
      now.toUTC(),
      (dateTime) => {
        const month = monthsWithMetrics.find((month) =>
          month.dateTime.equals(dateTime)
        );
        if (month) {
          allMonths.push(month);
        } else {
          allMonths.push(new Month({ dateTime: dateTime }));
        }
      }
    );
    return allMonths.reverse();
  }, [flue, now]);

  useHotkeys(Key.ArrowLeft, () => {
    props.timeframe.onNextTimeframe();
  });

  useHotkeys(Key.ArrowRight, () => {
    props.timeframe.onPreviousTimeframe();
  });

  const sx = useMemo(
    () =>
      merge({}, props.sx, {
        overflowX: "auto",
        scrollbarWidth: "none",
        overflowY: "visible",
        position: "relative",
        "> .MuiButton-root": {
          marginBottom: "5px",
          marginRight: "5px",
          marginLeft: "2px",
          marginTop: "2px",
        },
      }),
    [props.sx]
  );

  const handleMonthSelect = (value: Month) => {
    props.timeframe.onTimeframeChange(value.toTimeframeObject());
  };

  const selectedMonth = useMemo(() => {
    return months.find((it) =>
      it.dateTime.equals(props.timeframe.timeframe.start)
    );
  }, [months, props.timeframe.timeframe.start]);

  return (
    <ScrollMenu gap={1} sx={sx} scrollToItem={selectedMonth?.id}>
      {months.map((month, index) => {
        const selected = month === selectedMonth;

        const metrics = flue.querySingleMetricsOrUndef(
          FlueMetricsTimeUnitEnum.Month,
          month.dateTime
        );

        return (
          <ScrollMenuItem itemKey={month.id} key={month.id}>
            <MonthItem
              month={month}
              selected={selected}
              metrics={metrics}
              color={props.color}
              onSelect={handleMonthSelect}
            />
          </ScrollMenuItem>
        );
      })}
    </ScrollMenu>
  );
};
