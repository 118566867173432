import { FC } from "react";
import { Stack, SxProps } from "@mui/material";
import { useSxMerge } from "shared-ts-mui";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export interface MetricsContainerProps {
  className: string;
  children: React.ReactNode;
  layout: SizeClass;
  sx?: SxProps;
}

export const MetricsContainer: FC<MetricsContainerProps> = (props) => {
  const sx = useSxMerge(props.sx, {
    flex: 1,
    gap: props.layout === SizeClass.Compact ? 1 : 2,
    minWidth: "100px",
    width: "110px",
    alignItems: "stretch",
    justifyContent: "center",
  });
  return (
    <Stack direction={"column"} className={props.className} useFlexGap sx={sx}>
      {props.children}
    </Stack>
  );
};
