import { FetchError, FetchErrorOptions } from "./FetchError";
import { ProblemDetailsDto } from "./ProblemDetails";

export interface ProblemDetailsFetchErrorOptions extends FetchErrorOptions {
  problemDetails: ProblemDetailsDto;
}

export class ProblemDetailsFetchError extends FetchError {
  public readonly problemDetails: ProblemDetailsDto;

  constructor(options: ProblemDetailsFetchErrorOptions) {
    super(options);
    this.problemDetails = options.problemDetails;
  }
}
