import { FC, useMemo } from "react";
import { Button, SxProps, Typography } from "@mui/material";
import {
  FlueMetricNaturalKey,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { Info } from "luxon";
import { merge } from "lodash";
import { Button_color } from "shared-ts-mui";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export interface FlueMetricTimeProps {
  value: FlueMetricNaturalKey;
  selected?: boolean;
  color?: Button_color;
  sx?: SxProps;
  onSelect: (value: FlueMetricNaturalKey) => void;
}

export const FlueMetricTimeItem: FC<FlueMetricTimeProps> = (props) => {
  const timeUnit = props.value.unit;
  const time = props.value.time;
  const now = useDateTimeNow();
  const selected = props.selected ?? false;
  const months = Info.months();

  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      minWidth: "fit-content",
      pl: 1,
      pr: 1,
      pt: 0.5,
      pb: 0.5,
      borderColor: selected ? "text.secondary" : "transparent",
    } as SxProps);
  }, [props.sx, selected]);

  const handleClick = () => {
    props.onSelect(props.value);
  };
  return (
    <Button
      color={selected ? "primary" : props.color ?? "inherit"}
      variant={selected ? "contained" : "text"}
      onClick={handleClick}
      sx={sx}
    >
      <Typography variant={"button"} noWrap>
        {timeUnit === FlueMetricsTimeUnitEnum.SinceSweep && "Siden feiing"}
        {timeUnit === FlueMetricsTimeUnitEnum.Year &&
          time !== undefined &&
          time.year}
        {timeUnit === FlueMetricsTimeUnitEnum.Month &&
          time !== undefined &&
          (now.year !== time.year
            ? months[time.month - 1] + " " + time.year
            : "" + months[time.month - 1])}
      </Typography>
    </Button>
  );
};
