import { CSSProperties, FC } from "react";

export interface MascotPeepingFromLeftProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotPeepingFromLeft: FC<MascotPeepingFromLeftProps> = (
  props
) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      viewBox="0 0 114.53 340.2"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={"xMinYMin"}
      style={props.style}
    >
      <defs>
        <style>{`
          .MascotPeepingFromLeft.cls-1 {
            fill: #97caca;
          }

          .MascotPeepingFromLeft.cls-2 {
            fill: ${hairColor};
          }

          .MascotPeepingFromLeft.cls-3 {
            fill: #211e1f;
          }

          .MascotPeepingFromLeft.cls-4 {
            fill: #fff;
          }

          .MascotPeepingFromLeft.cls-5 {
            fill: none;
            stroke: #211e1f;
            stroke-miterlimit: 10;
            stroke-width: 1.59088px;
          }`}</style>
      </defs>
      <path
        className="MascotPeepingFromLeft cls-2"
        d="m94.642 232.04c30.572-21.372 24.521-71.869-8.7689-87.225-20.367-7.0174-0.0888-18.33-2.7941-32.973-3.3692-11.988-19.167-10.918-22.264-22.804-2.6983-10.4 8.2946-15.334 5.4537-25.588-3.1573-11.397-14.892-10.189-22.273-22.919-5.0854-8.7696 6.8688-20.845-4.6903-32.752-23.557-24.265-54.706 13.93-22.292 36.922 3.8038 2.6982 9.7427 5.0115 11.661 10.553 3.315 9.5773-7.3839 16.181-5.3576 26.44 2.3252 11.773 17.844 10.314 22.267 22.804 4.7855 13.515-10.272 22.59-5.8478 36.124 3.7856 11.581 18.804 10.403 21.954 21.827 3.2796 10.411-10.274 19.592-15.524 26.669-28.673 35.757 15.189 68.684 48.476 42.921z"
      />
      <polygon
        className="MascotPeepingFromLeft cls-1"
        transform="translate(-240.37 -127.54)"
        points="240.38 315.87 252.56 330.23 240.37 340.58 240.37 467.74 315.3 404.15 325.68 416.37 344.85 400.1 259.55 299.6"
      />
      <g transform="translate(-240.37 -127.54)">
        <path
          className="MascotPeepingFromLeft cls-4"
          d="m255.03 362.91c-1.3092 1.0617-2.2336 2.4252-2.7542 3.9082l9.4262 11.624c1.5585-0.20304 3.0836-0.82589 4.3928-1.8876 3.7685-3.056 4.3458-8.5861 1.2898-12.355-3.056-3.7684-8.5861-4.3458-12.355-1.2898z"
        />
        <circle
          className="MascotPeepingFromLeft cls-3"
          cx="260.71"
          cy="370.83"
          r="2.0913"
        />
        <rect
          className="MascotPeepingFromLeft cls-3"
          transform="rotate(221.5 278.53 366.86)"
          x="260.28"
          y="365.17"
          width="36.499"
          height="3.3629"
          rx="1.6815"
          ry="1.6815"
        />
        <path
          className="MascotPeepingFromLeft cls-4"
          d="m266.29 377.76c-1.2858 1.0899-2.1804 2.4733-2.6686 3.9672l9.6767 11.416c1.5538-0.23689 3.0649-0.89276 4.3508-1.9827 3.7011-3.1372 4.158-8.6786 1.0208-12.38s-8.6786-4.158-12.38-1.0208z"
        />
        <circle
          className="MascotPeepingFromLeft cls-3"
          cx="272.15"
          cy="384.5"
          r="2.0913"
        />
        <path
          className="MascotPeepingFromLeft cls-5"
          d="m262.53 392.26c-1.8525 0.35952-6.0984 0.88186-10.044-1.6249-3.1814-2.0215-4.702-5.0819-5.3853-6.8094"
        />
      </g>
    </svg>
  );
};
