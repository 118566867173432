import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SentryUserFeedbackForm } from "shared-ts-sentry";

export const UserFeedbackPage: FC = () => {
  const { t } = useTranslation("shared-ts-sentry");

  return (
    <AppPage
      $key={"UserFeedback"}
      name={t("Feedback")}
      mainPaneProps={{ scrollableContentVertically: true }}
      mainPane={
        <Box>
          <Container maxWidth={"md"}>
            <SentryUserFeedbackForm />
          </Container>
        </Box>
      }
    />
  );
};
