export interface ProblemDetailsDto {
  type: string;
  title: string;
  status: number;
  detail?: string;
}

export function isProblemDetailsDto(obj: object): obj is ProblemDetailsDto {
  const check =
    typeof obj === "object" &&
    "type" in obj &&
    typeof obj.type === "string" &&
    "title" in obj &&
    typeof obj.title === "string" &&
    "status" in obj &&
    typeof obj.status === "number";
  return check;
}
