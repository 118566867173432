import React from "react";
import {
  publicApiType,
  VisibilityContext,
} from "react-horizontal-scrolling-menu";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Button } from "shared-ts-mui";

export const LeftArrow = () => {
  const visibility = React.useContext<publicApiType>(VisibilityContext);

  const disabled = visibility.useLeftArrowVisible();
  const handleClick = () => {
    visibility.scrollPrev();
  };
  return (
    <Button
      color={"secondary"}
      className={"Arrow LeftArrow"}
      variant="outlined"
      onClick={handleClick}
      disabled={disabled}
      startIcon={<ArrowBackIosNewRoundedIcon />}
      sx={{ mr: 1, opacity: disabled ? 0.4 : 1 }}
    />
  );
};
