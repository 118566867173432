import React, { FC, useMemo } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Stack } from "@mui/material";
import { useMyChimneyContext } from "../MyChimneyContext";
import { BuildingTreeView } from "./BuildingTreeView";
import { AddressId } from "@airmont/firefly/shared/ts/domain";
import {
  BuildingSelect,
  Chimney,
  Fireplace,
  Flue,
} from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { ChimneyPanel } from "./ChimneyPanel";
import { FireplacePanel } from "./FireplacePanel";
import { FluePanel } from "./FluePanel";
import {
  DictionarySetting,
  useUserSetting,
} from "@airmont/shared/ts/utils/user-settings";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export const CombustionPlantPage: FC = (props) => {
  const {
    buildings,
    selectedBuilding,
    setSelectedBuilding,
    onChimneyUpdate,
    onFlueUpdate,
    onFireplaceUpdate,
  } = useMyChimneyContext();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const [selectedObjectId, setSelectedObjectId] = useUserSetting<{
    id: string;
    type: "Chimney" | "Flue" | "Fireplace";
  } | null>(
    `SettingsPage.${selectedBuilding.id}.selectedObject`,
    DictionarySetting
  );

  const selectedObject = useMemo(() => {
    if (selectedObjectId == null) {
      return undefined;
    } else if (selectedObjectId?.type === "Chimney") {
      return selectedBuilding.findChimneyById(selectedObjectId.id);
    } else if (selectedObjectId?.type === "Flue") {
      return selectedBuilding.findFlueById(selectedObjectId.id);
    } else if (selectedObjectId?.type === "Fireplace") {
      return selectedBuilding.findFireplaceById(selectedObjectId.id);
    } else {
      throw new IllegalStateError(
        `selectedObjectId.type not supported: ${selectedObjectId?.type}`
      );
    }
  }, [selectedBuilding, selectedObjectId]);

  const handleSelectedChimney = (chimney: Chimney) => {
    setSelectedObjectId({
      id: chimney.id,
      type: chimney.type,
    });
  };

  const handleSelectedFlue = (flue: Flue) => {
    setSelectedObjectId({
      id: flue.id,
      type: flue.type,
    });
  };

  const handleSelectedFireplace = (fireplace: Fireplace) => {
    setSelectedObjectId({
      id: fireplace.id,
      type: fireplace.type,
    });
  };

  const handleDwellingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };

  return (
    <AppPage
      $key={"CombustionPlantPage"}
      name={t("Combustion Plant")}
      icon={SettingsRoundedIcon}
      mainPaneActions={
        <BuildingSelect
          buildings={buildings}
          selected={selectedBuilding.id}
          onChange={handleDwellingChange}
        />
      }
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Stack direction={"column"} gap={1} useFlexGap sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            {selectedObject !== undefined &&
              selectedObject.type === "Chimney" && (
                <ChimneyPanel
                  chimney={selectedObject}
                  environment={selectedBuilding.environment.id}
                  onChange={onChimneyUpdate}
                />
              )}
            {selectedObject !== undefined && selectedObject.type === "Flue" && (
              <FluePanel
                flue={selectedObject}
                environment={selectedBuilding.environment.id}
                onChange={onFlueUpdate}
              />
            )}
            {selectedObject !== undefined &&
              selectedObject.type === "Fireplace" && (
                <FireplacePanel
                  fireplace={selectedObject}
                  environment={selectedBuilding.environment.id}
                  onChange={onFireplaceUpdate}
                />
              )}
          </Box>
        </Stack>
      }
      supportingPaneProps={{
        label: t("Combustion Plant"),
        resizePanelProps: {
          minWidth: 300,
          maxWidth: 400,
          minHeight: 150,
        },
      }}
      supportingPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Stack direction={"column"} gap={1} useFlexGap sx={{ flexGrow: 1 }}>
            <BuildingTreeView
              building={selectedBuilding}
              selectedObject={selectedObjectId}
              sx={{ flexGrow: 1 }}
              onSelectedFireplace={handleSelectedFireplace}
              onSelectedFlue={handleSelectedFlue}
              onSelectedChimney={handleSelectedChimney}
            />
          </Stack>
        </Box>
      }
    />
  );
};
