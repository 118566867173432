import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { LoadingButton } from "@mui/lab";
import { Form, Formik, FormikErrors } from "formik";
import { ResetPasswordResult } from "./IdentityDao";
import { useIdentityDao } from "./useIdentityDao";
import { FormikProperty, PropertyMode } from "shared-ts-property";
import { PasswordValidator } from "./PasswordValidator";

type ResetPasswordFormValues = {
  username: string;
  code: string;
  password: string;
  confirmPassword: string;
};

export const SetNewPasswordForm: FC = () => {
  const { t } = useTranslation("shared-ts-ui-identity");
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );
  const [resetPasswordResult, setResetPasswordResult] = useState<
    ResetPasswordResult | undefined
  >();
  const [resetPasswordResultDescription, setResetPasswordResultDescription] =
    useState<string | undefined>();
  const identityDao = useIdentityDao();
  const propertyMode: PropertyMode =
    resetPasswordResult === "invalid-token" ||
    resetPasswordResult === "succeeded"
      ? "read"
      : "edit";
  const initialValues: ResetPasswordFormValues = {
    username: searchParams.get("email") ?? "",
    code: searchParams.get("code") ?? "",
    password: "",
    confirmPassword: "",
  };

  const handleValidate = (
    values: ResetPasswordFormValues
  ): FormikErrors<ResetPasswordFormValues> => {
    const errors: FormikErrors<ResetPasswordFormValues> = {};

    if (values.username.isBlank()) {
      errors.username = t("Required");
    }

    const passwordValidationResult = new PasswordValidator({
      t: t,
      requiredLength: 6,
    }).validate(values);

    if (passwordValidationResult.password != null) {
      errors.password = passwordValidationResult.password;
    }
    if (passwordValidationResult.confirmPassword != null) {
      errors.confirmPassword = passwordValidationResult.confirmPassword;
    }
    return errors;
  };

  const handleSubmit = async (values: ResetPasswordFormValues) => {
    const resetPasswordResponse = await identityDao.resetPassword({
      email: values.username,
      code: values.code,
      password: values.password,
      confirmPassword: values.confirmPassword,
    });
    setResetPasswordResult(resetPasswordResponse.result);
    setResetPasswordResultDescription(resetPasswordResponse.description);
  };

  const handleTryAgainClick = () => {
    window.location.href = "/";
  };

  const handleGotoLoginClick = () => {
    window.location.href = "/";
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={handleValidate}
    >
      {(formik) => {
        const handleSubmitClick = () => {
          formik.submitForm();
        };

        return (
          <Form>
            <PropertiesCard
              header={{
                title: t("Set New Password"),
              }}
              sx={{
                justifyContent: "center",
              }}
            >
              <input
                name={"username"}
                value={formik.values.username}
                autoComplete={"username"}
                hidden={true}
                readOnly={true}
              />
              <FormikProperty
                formik={formik}
                name={"username"}
                label={t("Email")}
                mode={"read"}
                autoComplete={"username"}
                fullWidth
              />
              <FormikProperty
                formik={formik}
                name={"password"}
                label={t("New Password")}
                type={"password"}
                mode={propertyMode}
                autoComplete={"new-password"}
                fullWidth
              />
              <FormikProperty
                formik={formik}
                name={"confirmPassword"}
                label={t("Confirm New Password")}
                type={"password"}
                mode={propertyMode}
                autoComplete={"new-password"}
                fullWidth
              />
              {(resetPasswordResult === undefined ||
                resetPasswordResult === "failed") && (
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", justifyContent: "space-evenly" }}
                >
                  <LoadingButton
                    color={"secondary"}
                    variant={"contained"}
                    disabled={!formik.dirty || !formik.isValid}
                    loading={formik.isSubmitting}
                    onClick={handleSubmitClick}
                  >
                    {t("Save")}
                  </LoadingButton>
                </Stack>
              )}
              {resetPasswordResult === "succeeded" && (
                <>
                  <Alert severity={"info"}>{t("Password Changed")}</Alert>
                  <Button onClick={handleGotoLoginClick}>{t("Log In")}</Button>
                </>
              )}
              {resetPasswordResult === "failed" && (
                <Alert severity={"info"}>
                  <AlertTitle>{t("Failed to Change Password")}</AlertTitle>
                  {resetPasswordResultDescription &&
                    resetPasswordResultDescription}
                </Alert>
              )}
              {resetPasswordResult === "invalid-token" && (
                <>
                  <Alert severity={"info"}>
                    {t("Change Password Token Expired")}
                    {t("Please request '{{val}}' again", {
                      val: t("Forgot Password"),
                    })}
                  </Alert>
                  <Button onClick={handleTryAgainClick}>
                    {t("Try again")}
                  </Button>
                </>
              )}
            </PropertiesCard>
          </Form>
        );
      }}
    </Formik>
  );
};
