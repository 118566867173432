import { Stack, SxProps, Typography } from "@mui/material";
import {
  BurnAssessment,
  BurnAssessmentColor,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { useSxMerge } from "shared-ts-mui";

export interface BurnAssessmentLegendProps {
  layout?: SizeClass;
  direction?: "row" | "column";
  hideLabel?: boolean;
  size?: "small" | "default";
  sx?: SxProps;
}

export const BurnAssessmentLegend: FC<BurnAssessmentLegendProps> = (props) => {
  const layout = props.layout ?? SizeClass.Medium;
  const size =
    props.size != null
      ? props.size
      : layout === SizeClass.Compact
      ? "small"
      : "default";
  const direction: "row" | "column" =
    props.direction ?? layout === SizeClass.Compact ? "column" : "column";
  const hideLabel = props.hideLabel ?? false;
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const BurnAssessmentExcellentIcon = resolveBurnAssessmentIcon(
    BurnAssessment.Good
  );
  const BurnAssessmentGoodIcon = resolveBurnAssessmentIcon(
    BurnAssessment.Normal
  );
  const BurnAssessmentBadIcon = resolveBurnAssessmentIcon(BurnAssessment.Bad);

  const sx = useSxMerge(props.sx, {
    justifyContent: "center",
  });
  return (
    <Stack
      className={"BurnAssessmentLegend"}
      useFlexGap
      gap={1}
      direction={direction}
      sx={sx}
    >
      {!hideLabel && (
        <Typography
          variant={size === "small" ? "caption" : undefined}
          color={"textSecondary"}
          sx={{ fontWeight: "inherit" }}
        >
          {tSharedDomain("Legend")}
          {":"}
        </Typography>
      )}
      <Stack
        direction={"row"}
        sx={{ alignItems: "center", flexWrap: "nowrap" }}
      >
        <BurnAssessmentExcellentIcon
          sx={{
            scale: size === "small" ? "0.6" : "1.2",
            color: BurnAssessmentColor[BurnAssessment.Good],
            ml: size === "small" ? "-3px" : 1,
            mr: size === "small" ? "-3px" : 0.5,
          }}
        />
        <Typography
          variant={size === "small" ? "caption" : undefined}
          color={"textSecondary"}
          noWrap
          sx={{ fontWeight: "inherit" }}
        >
          = {tSharedDomain(`BurnAssessment.${BurnAssessment.Good}`)}
        </Typography>
      </Stack>
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <BurnAssessmentGoodIcon
          sx={{
            scale: size === "small" ? "0.5" : "1",
            color: BurnAssessmentColor[BurnAssessment.Normal],
            ml: size === "small" ? undefined : 1,
            mr: size === "small" ? "-1px" : 0.7,
          }}
        />
        <Typography
          variant={size === "small" ? "caption" : undefined}
          color={"textSecondary"}
          noWrap
          sx={{ fontWeight: "inherit" }}
        >
          = {tSharedDomain(`BurnAssessment.${BurnAssessment.Normal}`)}
        </Typography>
      </Stack>
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <BurnAssessmentBadIcon
          sx={{
            scale: size === "small" ? "0.5" : "1",
            color: BurnAssessmentColor[BurnAssessment.Bad],
            ml: size === "small" ? undefined : 1,
            mr: size === "small" ? "-1px" : 0.7,
          }}
        />
        <Typography
          variant={size === "small" ? "caption" : undefined}
          color={"textSecondary"}
          noWrap
          sx={{ fontWeight: "inherit" }}
        >
          = {tSharedDomain(`BurnAssessment.${BurnAssessment.Bad}`)}
        </Typography>
      </Stack>
    </Stack>
  );
};
