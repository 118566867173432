import { CSSProperties, FC } from "react";
import { merge } from "lodash";

export interface MyChimneyIconProps {
  hideHair?: boolean;
  hideText?: boolean;
  color?: string;
  style?: CSSProperties;
  mode: "light" | "dark";
}

export const MyChimneyIcon: FC<MyChimneyIconProps> = (props) => {
  const hideHair = props.hideHair ?? false;
  const hideText = props.hideText ?? false;
  const color = props.color ?? "#99cccc";

  const style = merge({}, props.style, {
    ...(hideHair && {
      padding: 0,
    }),
  });
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      viewBox={`${hideHair ? "30" : "30"} ${
        hideHair ? "30" : "0"
      } 342.11 392.28`}
    >
      <defs>
        <style>{`
          .MyChimneyIcon.cls-1 {
            fill: ${color};
          }

          .MyChimneyIcon.cls-2 {
            fill: ${hairColor};
          }`}</style>
      </defs>
      {!hideHair && (
        <path
          className="MyChimneyIcon cls-2"
          d="m255.83 57.225c-5.3838-39.198-55.978-57.915-86.579-33.92-16.477 15.831-17.346-8.784-32.477-13.314-12.946-2.6194-19.579 12.811-32.297 9.9844-11.123-2.4843-10.461-15.248-21.516-17.527-12.285-2.5331-16.823 9.1276-32.41 9.9373-10.738 0.55779-16.354-16.568-33.185-11.417-34.301 10.497-13.316 58.379 24.069 38.905 4.3872-2.2853 9.4439-6.7722 15.603-5.9019 10.644 1.5041 11.702 14.798 22.366 17.848 12.238 3.5005 18.368-11.854 32.298-9.9865 15.072 2.0208 16.355 20.625 31.271 22.996 12.763 2.0291 18.917-12.717 31.224-10.165 11.415 1.9411 13.523 19.176 17.664 27.556 19.882 44.364 72.182 18.891 63.968-24.995z"
        />
      )}
      <g transform="translate(-126.58 -101.5)">
        <path
          className="MyChimneyIcon cls-1"
          d="m325.79 485.21-14.147 0.64308-5.0291 0.22959-88.667 4.0876 18.05-31.645 53.439 11.436-45.309-48.915c6.1084-3.9728 12.263-7.9229 18.372-11.896 0.0919-0.046 0.16091-0.1148 0.2523-0.16077 7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919-5.5808 4.0188-20.922 13.32-26.755 17.476-0.0919 0.046-0.16035 0.1148-0.22875 0.16077-7.3032 5.2359-14.583 10.518-21.886 15.754l38.144 45.815 3.17 3.8121 8.91 10.679z"
        />
        <path
          className="MyChimneyIcon cls-1"
          d="m411.27 489.11-103.27 4.6618 3.6286-7.8539 0.0224-0.0688 5.2366-11.322 1.8367-3.9728 3.2148-6.9812 44.093 5.833-38.42-52.245c4.593-2.7328 9.1859-5.4426 13.755-8.1754 7.0273-4.2026 14.078-8.3592 21.082-12.539 8.8876 0.66607 17.798 1.286 26.685 1.9291-6.017 3.5365-12.011 7.0732-18.028 10.61-5.9251 3.4907-11.896 7.0272-17.844 10.518l58.008 69.606z"
        />
        <path d="m324.44 391.51c-5.5808 4.0188-20.922 13.32-26.755 17.476h-34.929c7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919z" />
        <path d="m297.69 409.01h-34.993l0.0488-0.0322c3.8755-2.5088 13.645-9.0098 23.093-15.297 9.1748-6.1055 17.841-11.872 21.275-14.098l5e-3 -3e-3h6e-3c3.1777 0.17285 5.6133 0.88086 7.6641 2.2295 1.8506 1.2158 3.2236 2.8486 4.6777 4.5772 1.4121 1.6797 2.873 3.416 4.9834 5.1152l0.0176 0.0147-0.0186 0.0127c-2.8262 2.0361-8.2461 5.4834-13.486 8.8174-5.1084 3.249-10.39 6.6084-13.269 8.6592l-4e-3 4e-3zm-34.876-0.0351h34.866c2.8804-2.0518 8.1597-5.4102 13.265-8.6572 5.2295-3.3262 10.636-6.7656 13.467-8.8027-2.1035-1.6963-3.5615-3.4297-4.9707-5.1055-1.4522-1.7266-2.8242-3.3574-4.6699-4.5713-2.0449-1.3438-4.4736-2.0508-7.6416-2.2227-3.4375 2.2275-12.1 7.9912-21.271 14.094-9.4092 6.2617-19.137 12.734-23.045 15.266z" />
        <path d="m389.13 398.38c-6.017 3.5365-12.011 7.0732-18.028 10.61h-29.74c7.0273-4.2026 14.078-8.3592 21.082-12.539 8.8876 0.66607 17.798 1.286 26.685 1.9291z" />
        <path d="m371.11 409.01h-29.808l0.0537-0.0322c3.7891-2.2656 7.6465-4.5547 11.377-6.7686 3.1846-1.8906 6.4775-3.8447 9.7051-5.7705l5e-3 -3e-3h5e-3c5.8203 0.43652 11.745 0.85938 17.476 1.2695 3.0195 0.21582 6.1426 0.43848 9.21 0.66016l0.0557 4e-3 -0.0478 0.0283c-3.0088 1.7686-6.0615 3.5664-9.0137 5.3047-2.9522 1.7393-6.0059 3.5371-9.0137 5.3047l-4e-3 3e-3zm-29.683-0.0351h29.673c3.0068-1.7666 6.0586-3.5644 9.0098-5.3018 2.9365-1.7295 5.9736-3.5176 8.9658-5.2773-3.0498-0.2207-6.1533-0.44238-9.1543-0.65625-5.7285-0.40918-11.652-0.83301-17.471-1.2686-3.2256 1.9248-6.5176 3.8789-9.7002 5.7676-3.7129 2.2031-7.5518 4.4814-11.323 6.7363z" />
        <path
          className="MyChimneyIcon cls-1"
          transform="rotate(5.151 331.06 304.68)"
          d="m236.85 210.47h188.42v165.57c0 12.611-10.239 22.85-22.85 22.85h-142.72c-12.611 0-22.85-10.239-22.85-22.85v-165.57z"
        />
        <rect
          className="MyChimneyIcon cls-1"
          transform="rotate(5.151 339.13 215.15)"
          x="211.27"
          y="190.5"
          width="255.73"
          height="49.298"
        />
        {!hideText && (
          <>
            <path d="m292.71 242.53 3.2459 37.356 0.17422 0.0157 9.8742-36.174 23.266 2.0973-5.594 62.056-15.22-1.372 4.1681-46.238-0.17422-0.0157-12.828 45.457-11.72-1.0565-4.4916-47.018-0.17534-0.0158-4.1681 46.238-15.219-1.3719 5.594-62.056 23.266 2.0973z" />
            <path d="m352.01 247.88-5.594 62.056-16.269-1.4666 5.594-62.056z" />
            <path d="m377.28 250.16 7.0484 39.187 0.17645 0.0159 3.4473-38.241 15.22 1.372-5.594 62.056-17.844-1.6086-7.3912-39.306-0.17422-0.0157-3.4551 38.328-15.22-1.372 5.594-62.056 18.193 1.64z" />
            <path d="m284.22 310.1c2.6748 0.24112 4.9572 0.88058 6.8473 1.9161 1.8913 1.0356 3.4129 2.3559 4.565 3.9584 1.1531 1.6049 1.9409 3.4448 2.3638 5.5176 0.42398 2.0728 0.52917 4.294 0.31536 6.6658-0.50498 5.6018-2.386 9.6672-5.6398 12.196-3.2549 2.5291-7.7847 3.5332-13.587 3.0102l-4.9194-0.44345-1.8558 20.587-14.078-1.269 4.8717-54.043 21.117 1.9036zm-6.4916 22.917c1.7659 0.15919 3.1629-0.24851 4.1933-1.2252 1.028-0.97459 1.6454-2.597 1.85-4.8675 0.39574-4.39-1.2229-6.7499-4.8561-7.0774l-2.6489-0.23878-1.1872 13.17 2.6489 0.23878z" />
            <path d="m317.82 313.13-4.8717 54.043-14.078-1.269 4.8717-54.043z" />
            <path d="m344.54 315.54c2.6748 0.24112 4.9572 0.88058 6.8473 1.9161 1.8913 1.0356 3.4129 2.3559 4.565 3.9584 1.1531 1.6049 1.9409 3.4448 2.3638 5.5176 0.42398 2.0728 0.52917 4.294 0.31536 6.6658-0.50498 5.6018-2.386 9.6672-5.6398 12.196-3.2549 2.5291-7.7847 3.5332-13.587 3.0102l-4.9194-0.44345-1.8558 20.587-14.078-1.269 4.8717-54.043 21.117 1.9036zm-6.4916 22.917c1.7659 0.15919 3.1629-0.24851 4.1933-1.2252 1.028-0.97459 1.6454-2.597 1.85-4.8675 0.39574-4.39-1.2229-6.7499-4.8561-7.0774l-2.6489-0.23878-1.1872 13.17 2.6489 0.23878z" />
            <path d="m396.61 320.23-1.0372 11.506-17.939-1.6171-0.83249 9.235 16.803 1.5147-0.99615 11.051-16.803-1.5147-0.96874 10.746 18.62 1.6785-1.0372 11.506-32.698-2.9475 4.8717-54.043 32.016 2.8861z" />
          </>
        )}
      </g>
    </svg>
  );
};
